import React, { FC, useCallback, useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import Alert from '../../../components/bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api/narlab';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}

interface ILoginProps {
	isSignUp?: boolean;
}
const AdminLogin: FC<ILoginProps> = ({ isSignUp }) => {
	const { t } = useTranslation(['auth']);

	const { darkModeStatus } = useDarkMode();
	const { user, setUser } = useContext(AuthContext);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/admin/page'), [navigate]);

	useEffect(() => {
		if (user.userPermission) {
			navigate('/admin/page');
		}
	}, []);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: 'service@ramatetech.com',
			loginPassword: '1234',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = '請輸入帳號';
			}

			if (!values.loginPassword) {
				errors.loginPassword = '請輸入密碼';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			const account = {
				managerEmail: values.loginUsername,
				managerPassword: values.loginPassword,
			};
			API.managerLogin(account)
				.then((res) => {
					// console.log(res);
					if (setUser) {
						setUser({
							userEmail: res.managerEmail,
							userId: res.managerId,
							userToken: res.managerToken,
							userPermission: res.managerPermission,
							userName: res.managerName,
						});
					}
					setTimeout(() => {
						navigate('/admin/page');
					}, 1000);
				})
				.catch((err) => {
					showNotification('錯誤', err.error, 'danger');
					// console.log(err);
					formik.setFieldError('loginPassword', err.error);
				});
		},
	});
	const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
		if (isNewUser) {
			return (
				<>
					<div className="text-center h1 fw-bold mt-1">
						{t('Sign Up Title')}
					</div>
					<div className="text-center h4 text-muted mb-5">
						{t('Sign Up Content')}
					</div>
				</>
			);
		}
		return (
			<>
				<div className="text-center h1 fw-bold mt-1">{t('Login Title')}</div>
				<div className="text-center h4 text-muted mb-5">
					{t('Login Content')}
				</div>
			</>
		);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? t('Sign Up') : t('Login')}
			className={classNames({
				'bg-warning': !singUpStatus,
				'bg-info': singUpStatus,
			})}
		>
			<Page className="p-0">
				<div className="row h-100 align-items-center justify-content-center">
					<div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
						<Card className="shadow-3d-dark" data-tour="login-page">
							<CardBody>
								<div className="text-center my-1">
									<Icon icon="AdminPanelSettings" size="4x" />
									<Link
										to="/"
										className={classNames(
											'text-decoration-none  fw-bold display-6',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											}
										)}
									>
										{/* <Logo width={300} /> */}
										<br />
										Fish-Eye Traffic Data Competition Website
									</Link>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<Alert isLight icon="Lock" isDismissible>
									<div className="row">
										<div className="col-12">
											<strong>Username:</strong> service@ramatetech.com
										</div>
										<div className="col-12">
											<strong>Password:</strong> 1234
										</div>
									</div>
								</Alert>
								<form className="row g-4">
									{singUpStatus ? (
										<>
											<div className="col-12">
												<FormGroup
													id="signup-email"
													isFloating
													label={t('username')}
												>
													<Input type="email" autoComplete="email" />
												</FormGroup>
											</div>
											<div className="col-12">
												<FormGroup
													id="signup-name"
													isFloating
													label={t('name')}
												>
													<Input autoComplete="given-name" />
												</FormGroup>
											</div>
											<div className="col-12">
												<FormGroup
													id="signup-password"
													isFloating
													label={t('password')}
												>
													<Input type="password" autoComplete="password" />
												</FormGroup>
											</div>
											<div className="col-12">
												<Button
													color="info"
													className="w-100 py-3"
													onClick={handleOnClick}
												>
													{t('Sign Up')}
												</Button>
											</div>
										</>
									) : (
										<>
											<div className="col-12">
												<FormGroup
													id="loginUsername"
													isFloating
													label={t('username')}
													// className={classNames({
													// 	'd-none': signInPassword,
													// })}
												>
													<Input
														autoComplete="username"
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={formik.errors.loginUsername}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{/* {signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>Hi, {formik.values.loginUsername}.</div>
												)} */}
												<FormGroup
													className="mt-3"
													id="loginPassword"
													isFloating
													label={t('password')}
													// className={classNames({
													// 	'd-none': !signInPassword,
													// })}
												>
													<Input
														type="password"
														autoComplete="current-password"
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={formik.errors.loginPassword}
														validFeedback="Looks good!"
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className="col-12">
												{/* {!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														{t('Continue')}
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														{t('Login')}
													</Button>
												)} */}
												<Button
													color="warning"
													type="submit"
													className="w-100 py-3"
													onClick={formik.handleSubmit}
												>
													{t('Login')}
												</Button>
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>OR</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													{t('Sign in with Apple')}
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													{t('Continue with Google')}
												</Button>
											</div>
										</>
									)} */}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
AdminLogin.propTypes = {
	isSignUp: PropTypes.bool,
};
AdminLogin.defaultProps = {
	isSignUp: false,
};

export default AdminLogin;
