import React, { useContext } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import classNames from 'classnames';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import AuthContext from '../../../contexts/authContext';
import Icon from '../../../components/icon/Icon';
import * as API from '../../../api/narlab';
import useSWR from 'swr';

const FishEyeHeader = () => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { user, setUser } = useContext(AuthContext);
	const navigate = useNavigate();
	const { data: navListData = { nav: [] } } = useSWR('/frontendNav', () =>
		API.getFrontendNav()
	);

	const styledBtn: IButtonProps = {
		color: 'dark',
		hoverShadow: 'default',
		isLight: darkModeStatus,
		size: 'lg',
	};
	const windowsWidth = useWindowSize().width;

	function handleLogout(userId: string) {
		if (user.userPermission) {
			API.managerLogout(userId)
				.then(() => {
					navigate(`/`);
					localStorage.removeItem('narlab');
					if (setUser) {
						setUser({});
					}
				})
				.catch(() => {
					localStorage.removeItem('narlab');
					navigate(`/`);
				});
		} else {
			API.narlabUserLogout(userId)
				.then((res) => {
					localStorage.removeItem('narlab');
					if (setUser) {
						setUser({});
					}
					if (res === 'success') {
						navigate('/');
					}
				})

				.catch((err) => {
					localStorage.removeItem('narlab');
					navigate('/');
				});
		}
	}

	return (
		<>
			<Header>
				<HeaderLeft
					className={classNames('text-decoration-none  fw-bold text-light', {
						h4: windowsWidth > 1200,
						h3: windowsWidth < 1200,
						// 'text-dark': !darkModeStatus,
						// 'text-light': darkModeStatus,
					})}
				>
					<span style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
						Fish-Eye Traffic Data Competition Website
					</span>
				</HeaderLeft>

				<HeaderRight>
					<div className="container">
						<div className="row g-3 justify-content-md-end">
							{Object.values(navListData.nav || {}).map((i, index) => {
								return i.page && i.page.length > 1 ? (
									<div className="col-auto" key={index}>
										<Dropdown className="" direction="down">
											<DropdownToggle hasIcon={true}>
												<Button {...styledBtn} className="w-100">
													{i.pageNavName}
												</Button>
											</DropdownToggle>
											<DropdownMenu isAlignmentEnd>
												{i.page?.map(({ pagePath, pageButton }, index) => (
													<DropdownItem key={index}>
														<Button
															icon="Circle"
															target="_blank"
															onClick={() => navigate(`/${pagePath}`)}
															// onClick={() => // console.log('11111', index.pagePath)}
														>
															{pageButton}
														</Button>
													</DropdownItem>
												))}
											</DropdownMenu>
										</Dropdown>
									</div>
								) : (
									<div className="col-auto p-0" key={index}>
										{i.page?.map(({ pagePath, pageButton }, index) => (
											<div
												key={index}
												style={{
													margin: pageButton ? '0px  6.5px 0px  6.5px' : '0px ',
												}}
											>
												<Button
													color="dark"
													{...styledBtn}
													onClick={() => navigate(`/${pagePath}`)}
													className="w-100"
												>
													{pageButton}
												</Button>
											</div>
										))}
									</div>
								);
							})}

							{user.userToken ? (
								<div className="col-auto col-xl-auto ">
									<Dropdown className="">
										<DropdownToggle hasIcon={true}>
											<Button
												color="dark"
												{...styledBtn}
												className="w-100"
												isLight
											>
												<Icon
													icon={user.userPermission ? 'VerifiedUser' : 'Person'}
													style={{ marginRight: 5 }}
													// size={'2x'}
												/>
												{user.userName}
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button
													icon="Assessment"
													onClick={() => navigate('/board')}
												>
													Leaderboard
												</Button>
											</DropdownItem>
											{user.userPermission ? null : (
												<DropdownItem>
													<Button
														icon="HistoryToggleOff"
														onClick={() => navigate('/history')}
													>
														History
													</Button>
												</DropdownItem>
											)}

											<DropdownItem>
												<Button
													icon="Logout"
													target="_blank"
													onClick={() => handleLogout(user.userId || '')}
												>
													Logout
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</div>
							) : (
								<>
									<div className="col-6 col-xl-auto ">
										<Button
											color="dark"
											{...styledBtn}
											isLight
											className="w-100"
											onClick={() => navigate('/board')}
										>
											<Icon icon="Assessment" size={'2x'} />
											Leaderboard
										</Button>
									</div>
									<div className="col-6 col-xl-auto ">
										<Button
											color="dark"
											{...styledBtn}
											className="w-100"
											isLight
											onClick={() => navigate('/login')}
										>
											<Icon icon="Person" size={'2x'} />
											Login
										</Button>
									</div>
								</>
							)}
						</div>
					</div>
				</HeaderRight>
			</Header>
		</>
	);
};

export default FishEyeHeader;
