import React, { FC, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import * as API from '../../../api/narlab';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';

interface ILoginProps {
	isSignUp?: boolean;
}
const Password: FC<ILoginProps> = ({ isSignUp }) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { managerId, managerToken } = useParams();
	const { darkModeStatus } = useDarkMode();
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const navigate = useNavigate();

	const formik = useFormik({
		// enableReinitialize: true,
		initialValues: {
			newPassword: '',
			rePassword: '',
		},
		validate: (values) => {
			const errors: { newPassword?: string; rePassword?: string } = {};

			if (!values.newPassword) {
				errors.newPassword = '請輸入新密碼';
			}

			if (/[\u4e00-\u9fa5]/gi.test(values.newPassword)) {
				errors.newPassword = '請勿輸入中文';
			}

			if (values.newPassword !== values.rePassword) {
				errors.rePassword = '兩次輸入的密碼不相同';
			}

			return errors;
		},
		// validateOnChange: false,
		onSubmit: async (values) => {
			if (!managerId || !managerToken) return;
			API.sendManagerPasswordEmail({
				managerId: managerId,
				managerPassword: values.newPassword,
				managerToken: managerToken,
			})
				.then((res) => {
					showNotification('成功', '已成功設定管理者密碼', 'success');
					navigate('/admin/login');
				})
				.catch((err) => {
					showNotification('錯誤', err.error, 'danger');
					formik.setFieldError('userEmail', err.error);
				});
		},
	});

	const LoginHeader = () => {
		return (
			<>
				<div className="text-center h1 fw-bold mt-1">設定管理員密碼</div>
				<div className="text-center h4 text-muted mb-5">
					Setting manager password
				</div>
			</>
		);
	};

	return (
		<PageWrapper
			isProtected={false}
			// title={singUpStatus ? t('Sign Up') : t('Login')}
			className={classNames({
				'bg-light': !singUpStatus,
				'bg-dark': singUpStatus,
			})}
		>
			<Page className="p-0">
				<div className="row h-100 align-items-center justify-content-center">
					<div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
						<Card
							className={classNames({
								'shadow-3d-dark': !singUpStatus,
								'shadow-3d-light': singUpStatus,
							})}
							data-tour="login-page"
						>
							<CardBody>
								<div className="text-center my-3">
									<Icon icon="AccountCircle" size="4x" />
									<Link
										to="/"
										className={classNames(
											'text-decoration-none  fw-bold display-6',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											}
										)}
									>
										<br />
										{/* <Logo width={300} /> */}
										Fish-Eye Traffic Data Competition Website
									</Link>
								</div>

								<LoginHeader />

								<form className="row g-4">
									<div className="col-12">
										<FormGroup id="newPassword" isFloating label="輸入新密碼">
											<Input
												type="password"
												autoComplete="password"
												value={formik.values.newPassword}
												isTouched={formik.touched.newPassword}
												invalidFeedback={formik.errors.newPassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className="col-12">
										<FormGroup
											id="rePassword"
											isFloating
											label="重新輸入新密碼"
										>
											<Input
												type="password"
												autoComplete="re-password"
												value={formik.values.rePassword}
												isTouched={formik.touched.rePassword}
												invalidFeedback={formik.errors.rePassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className="col-12">
										{/* {!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														{t('Continue')}
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														{t('Login')}
													</Button>
												)} */}
										<Button
											color="warning"
											type="submit"
											className="w-100 py-3"
											onClick={formik.handleSubmit}
											isDisable={isSubmitting}
											isActive={isSubmitting}
										>
											傳送
										</Button>
									</div>

									{/* BEGIN :: Social Login */}
									{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>OR</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													{t('Sign in with Apple')}
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													{t('Continue with Google')}
												</Button>
											</div>
										</>
									)} */}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Password.propTypes = {
	isSignUp: PropTypes.bool,
};
Password.defaultProps = {
	isSignUp: false,
};

export default Password;
