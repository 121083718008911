import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  ModelsManagerLoginRes,
  ModelsUserLoginRes,
  ModelsUserScoreList,
} from '../swagger/narlab';
// import {
// 	getUserDataWithUsername,
// 	IUserProps,
// } from '../common/data/userDummyData';

export interface IUser {
  userEmail: string;
  userId: string;
  userToken: string;
  userLevel?: string;
  userName: string;
  userOrganization?: string;
  userScoreList?: ModelsUserScoreList[];
  userPermission: string;
}

export interface IAuthContextProps {
  user: Partial<IUser>;
  setUser?(...args: unknown[]): unknown;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({
  children,
}) => {
  // 從 localStorage 取得使用者的資料
  const [user, setUser] = useState<Partial<IUser>>({});

  useEffect(() => {
    if (!user?.userToken) {
      const localUser = localStorage.getItem('narlab');
      if (localUser && localUser !== '{}') {
        const data = JSON.parse(localUser || '');
        setUser(data);
      }
    } else {
      localStorage.setItem('narlab', JSON.stringify(user));
    }
  }, [user]);

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
