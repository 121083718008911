import React, { FC, useContext, useState } from 'react';
import useDarkMode from '../../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import Icon from '../../../../components/icon/Icon';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import classNames from 'classnames';
import Badge from '../../../../components/bootstrap/Badge';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import Button from '../../../../components/bootstrap/Button';
import {
	Droppable,
	DroppableProvided,
	DroppableStateSnapshot,
} from '@hello-pangea/dnd';
import { getListStyle } from '../helper/style';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../../../components/bootstrap/Modal';
import { TCards, TColumnData, TColumnsData } from '../type/types';
import ColumnCardWrapper from './ColumnCardWrapper';
import { mutate } from 'swr';
import * as API from '../../../../api/narlab';
import { useDispatch } from 'react-redux';
import showNotification from '../../../../components/extras/showNotification';
import AuthContext from '../../../../contexts/authContext';

interface IColumns {
	cardsData: TCards;
	columnsData: TColumnsData;
	setCardsData(...args: unknown[]): unknown;
}
const Columns: FC<IColumns> = ({ cardsData, columnsData, setCardsData }) => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useContext(AuthContext);
	const [editModalStatus, setEditModalStatus] = useState<boolean>(false);
	const [editPageNavName, setEditPageNavName] = useState('');
	const [editPageNavId, setEditPageNavId] = useState('');
	// const [columns, setColums] = useState<number>(0);
	const dispatch = useDispatch();
	const keysCount = Object.keys(columnsData).length;
	const formik = useFormik({
		initialValues: {
			pageNavName: '',
		},

		validate: (values) => {
			const errors: {
				pageNavName?: string;
			} = {
				pageNavName: undefined,
			};

			if (!values.pageNavName) {
				errors.pageNavName = '請輸入導覽列名稱';
			}

			if (values.pageNavName.length > 10) {
				errors.pageNavName = '字數不得超過10字元';
			}

			//錯誤判斷
			const navErrors = Object.values(errors).reduce((map, current) => {
				if (current) {
					return true;
				}
				return map;
			}, false);

			if (!navErrors) {
				return {};
			}
			return errors;
		},

		onSubmit: (values) => {
			if (editPageNavName) {
				API.updateNavName({
					pageNavId: editPageNavId || '',
					pageNavName: values.pageNavName || '',
				})
					.then((res) => {
						// console.log(res);
						if (res === 'success') {
							showNotification(
								<span className="d-flex align-items-center">
									<Icon icon="Delete" size="lg" className="me-1" />
									<span>已成功更新{values.pageNavName}欄位。</span>
								</span>,
								'The nav name have been Added.'
							);
							setEditModalStatus(false);
							// mutate('/navList');
							mutate('/nav');
						}
					})
					.catch((err) => {
						// console.log(err.error.messanger);
						showNotification('錯誤', err.error, 'danger');
					});
			} else {
				API.addNav({
					execManagerId: user.userId || '',
					pageNavName: values.pageNavName || '',
				})
					.then((res) => {
						setCardsData({
							...cardsData,
							['column' + (Object.keys(cardsData).length + 1)]: [],
						});
						if (res === 'success') {
							showNotification(
								<span className="d-flex align-items-center">
									<Icon icon="Delete" size="lg" className="me-1" />
									<span>已成功新增{values.pageNavName}。</span>
								</span>,
								'The nav have been Added.'
							);
							setEditModalStatus(false);
							// mutate('/navList');
							mutate('/nav');
						}
					})
					.catch((err) => {
						// console.log(err.error.messanger);
						showNotification('錯誤', err.error, 'danger');
					});
			}
			// API.updateNav({
			// 	execManagerId: execManagerId,
			// 	pageNavName: values.pageNavName || '',
			// })
			// 	.then((res) => {
			// 		// console.log(res);
			// 		if (res === 'success') {
			// 			showNotification(
			// 				<span className="d-flex align-items-center">
			// 					<Icon icon="Info" size="lg" className="me-1" />
			// 					<span>已成功修改 {values.pageNavName} 導覽列。</span>
			// 				</span>,
			// 				'The nav have been successfully updated.',
			// 				'success'
			// 			);
			// 			mutate('/navList', '/nav');
			// 			setEditModalStatus(false);
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		// console.log(err.error.messanger);
			// 		showNotification('錯誤', err.error, 'danger');
			// 	});
		},
	});

	function handleClickDelete(
		navId: string,
		pageNavName: string,
		columnKey: string
	) {
		dispatch({
			type: 'CONFIRM_SHOW',
			payload: {
				title: '刪除導覽列',
				content: `確定要刪除${pageNavName} 欄位?如要刪除文章將回歸未綁定文章。`,
				func: () => {
					API.deleteNav(navId, user.userId || '')
						.then((res) => {
							const newData = cardsData;

							const newCardsData = Object.keys(newData).reduce(
								(map, current, index) => {
									// console.log(
									//   '當前處理中column:',
									//   index + 1,
									//   ', 欲刪除的columnKey:',
									//   columnKey,
									//   ', 當前Index:',
									//   index
									// );
									if (columnKey === current) {
										// console.log('columnKey === current , return');
										const newMap = map as any;
										newMap['column1'] = [
											...newMap['column1'],
											...newData[current],
										];
										return newMap;
									}
									if (
										parseInt(columnKey.replace('column', ''), 10) <
										index + 1
									) {
										// console.log(
										//   '發現columnKey小於column,  columnKey',
										//   columnKey,
										//   ' , 當前key尾:',
										//   parseInt(columnKey.replace('column', ''), 10),
										//   ', index:',
										//   index
										// );
										return {
											...map,
											['column' + index]: cardsData[current],
										};
									} else {
										// console.log('其餘index+1');
										return {
											...map,
											['column' + (index + 1)]: cardsData[current],
										};
									}
								},
								{ column1: [] as any }
							);
							// console.log('ori: ', cardsData);
							// console.log('after: ', newCardsData);
							setCardsData(newCardsData);
							if (res === 'success') {
								showNotification(
									<span className="d-flex align-items-center">
										<Icon icon="Delete" size="lg" className="me-1" />
										<span>已成功 刪除{pageNavName}。</span>
									</span>,
									'The nav have been deleted.'
								);
								// mutate('/navList');
								mutate('/nav');
							}
						})

						.catch((err) => {
							// console.log(err.error);
							showNotification('錯誤', err.error, 'danger');
						});
				},
			},
		});
	}

	function handleClickEdit(pageNavId: string, pageNavName: string) {
		setEditPageNavName(pageNavName);
		setEditPageNavId(pageNavId);
		setEditModalStatus(true);
		formik.setValues({
			pageNavName: pageNavName || '',
		});
	}

	function handleClickAdd() {
		setEditModalStatus(true);
		formik.resetForm();
		setEditPageNavName('');
	}

	return (
		<>
			{Object.keys(columnsData).map((columnKey) => {
				const columnData: TColumnData = columnsData[columnKey];

				return (
					<div key={columnKey} className="col-auto">
						<Card
							className={classNames(
								`board-group shadow-3d-${
									columnData?.pageNavId.length > 1 ? 'info' : 'dark'
								}`
							)}
						>
							<CardHeader>
								<CardLabel
									icon={
										columnData?.pageNavId?.length > 1
											? 'FeaturedPlayList'
											: 'CancelPresentation'
									}
									iconColor={
										columnData?.pageNavId?.length > 1 ? 'info' : 'dark'
									}
								>
									<CardTitle>
										{columnData?.pageNavName}
										{/* {columnKey} */}
										<Badge
											isLight
											color={
												columnData?.pageNavId?.length > 1 ? 'info' : 'dark'
											}
										>
											{cardsData[columnKey]?.length || 0}
										</Badge>
									</CardTitle>
								</CardLabel>
								{columnData?.pageNavId?.length > 1 ? (
									<CardActions>
										<Dropdown>
											<DropdownToggle hasIcon={false}>
												<Button icon="MoreVert" color={'info'} />
											</DropdownToggle>
											<DropdownMenu isAlignmentEnd>
												<DropdownItem>
													<Button
														icon="Edit"
														onClick={() =>
															handleClickEdit(
																columnData.pageNavId,
																columnData.pageNavName
															)
														}
													>
														編輯導覽頁名稱
													</Button>
												</DropdownItem>

												<DropdownItem isDivider />
												<DropdownItem>
													<Button
														icon="Delete"
														onClick={() =>
															handleClickDelete(
																columnData.pageNavId,
																columnData.pageNavName,
																columnKey
															)
														}
													>
														刪除
													</Button>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
									</CardActions>
								) : null}
							</CardHeader>
							<Droppable droppableId={columnKey}>
								{(
									providedDroppable: DroppableProvided,
									snapshotDroppable: DroppableStateSnapshot
								) => (
									<>
										<CardBody
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...providedDroppable.droppableProps}
											ref={providedDroppable.innerRef}
											style={getListStyle(snapshotDroppable.isDraggingOver)}
										>
											<ColumnCardWrapper
												columnKey={columnKey}
												columnsData={columnsData}
												cardsData={cardsData}
												setCardsData={setCardsData}
											/>
											{providedDroppable.placeholder}
										</CardBody>
									</>
								)}
							</Droppable>
						</Card>
					</div>
				);
			})}
			{keysCount <= 8 ? (
				<div className="col-auto">
					<Card>
						<CardBody>
							<Button color="info" icon="Add" onClick={() => handleClickAdd()}>
								新增
							</Button>
						</CardBody>
					</Card>
				</div>
			) : (
				''
			)}

			<Modal
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				size="lg"
				data-tour="mail-app-modal"
			>
				<ModalHeader className="px-4" setIsOpen={setEditModalStatus}>
					<CardLabel icon="Person" iconColor="dark">
						<CardTitle>編輯導覽列名稱</CardTitle>
					</CardLabel>
				</ModalHeader>

				<ModalBody className="px-4">
					<form onSubmit={formik.handleSubmit} noValidate>
						<CardBody>
							<div className="row g-3 pb-4">
								<div className="col-12">
									<FormGroup id="pageNavName" label="導覽列名稱：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.pageNavName}
											placeholder="導覽列名稱"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.pageNavName}
											isTouched={formik.touched?.pageNavName}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>
							</div>
						</CardBody>
					</form>
				</ModalBody>
				<ModalFooter className="px-4 pb-4">
					<Button
						color="dark"
						className="w-100"
						type="submit"
						onClick={formik.handleSubmit}
					>
						儲存
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default Columns;
