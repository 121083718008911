import React, { useContext, useState, useRef } from 'react';
import classNames from 'classnames';
import Button from '../../components/bootstrap/Button';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Input from '../../components/bootstrap/forms/Input';
import Label from '../../components/bootstrap/forms/Label';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import Icon from '../../components/icon/Icon';
import { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import useSortableData from '../../hooks/useSortableData';
import useDarkMode from '../../hooks/useDarkMode';
import { useFormik } from 'formik';
import Badge from '../../components/bootstrap/Badge';
import AuthContext from '../../contexts/authContext';
import * as API from '../../api/narlab';
import useSWR from 'swr';
import showNotification from '../../components/extras/showNotification';
import InputGroup, {
	InputGroupText,
} from '../../components/bootstrap/forms/InputGroup';

const Board = () => {
	const { darkModeStatus } = useDarkMode();
	const fileInputRef1 = useRef<HTMLInputElement>(null);
	const fileInputRef2 = useRef<HTMLInputElement>(null);
	const fileInputRef3 = useRef<HTMLInputElement>(null);
	const [openRowId, setOpenRowId] = useState<number | null>(null);
	const { user } = useContext(AuthContext);
	const [userFile1, setUserFile1] = useState<any>();
	const [userFile2, setUserFile2] = useState<any>();
	const [userFile3, setUserFile3] = useState<any>();
	const [fileName1, setFileName1] = useState<any>();
	const [fileName2, setFileName2] = useState<any>();
	const [fileName3, setFileName3] = useState<any>();
	async function handleChangeFile1(file1: File) {
		// const arrayBuffer = await getBase64(file);
		const extension1 = '.' + file1.name.split('.')[1];
		// const url = URL.createObjectURL(file);
		setUserFile1(file1);
		setFileName1(file1.name.split('.')[0]);
		// formik.setFieldValue('file', file);
		// formik.setFieldValue('file', url);
		formik.setFieldValue('extension', extension1);
	}
	async function handleChangeFile2(file2: File) {
		const extension2 = '.' + file2.name.split('.')[1];
		setUserFile2(file2);
		setFileName2(file2.name.split('.')[0]);
		formik.setFieldValue('extension', extension2);
	}

	async function handleChangeFile3(file3: File) {
		const fileName3 = file3.name.split('.')[0];
		const extension3 = '.' + file3.name.split('.')[1];
		setUserFile3(file3);
		setFileName3(file3.name.split('.')[0]);
		formik.setFieldValue('extension', extension3);
	}

	function numberUp(e: number) {
		const roundedNum: number = Math.ceil(e * 100) / 100;
		return roundedNum;
	}

	const formik = useFormik({
		initialValues: {
			searchInput: '',
			file1: File,
			file2: File,
			file3: File,
			extension1: '',
			extension2: '',
			extension3: '',
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			if (!userFile1) {
				showNotification(
					<span className="d-flex align-items-center">
						<Icon icon="Info" size="lg" className="me-1" />
						<span>You haven't uploaded the File1 yet.</span>
					</span>,
					'',
					'danger'
				);
			}
			if (!userFile2) {
				showNotification(
					<span className="d-flex align-items-center">
						<Icon icon="Info" size="lg" className="me-1" />
						<span>You haven't uploaded the File2 yet.</span>
					</span>,
					'',
					'danger'
				);
			}
			if (!userFile3) {
				showNotification(
					<span className="d-flex align-items-center">
						<Icon icon="Info" size="lg" className="me-1" />
						<span>You haven't uploaded the File3 yet.</span>
					</span>,
					'',
					'danger'
				);
			}
			if (!user || !user.userId || !userFile1 || !userFile2 || !userFile3)
				return;

			const req1 = {
				file1: userFile1,
				file2: userFile2,
				file3: userFile3,
				userId: user?.userId,
				file1Extension: values.extension1,
				file2Extension: values.extension2,
				file3Extension: values.extension3,
				fileName1: fileName1,
				fileName2: fileName2,
				fileName3: fileName3,
			};

			// const form = new FormData();
			// // form.append('file', userFile);
			// form.append('userId', user?.userId);
			// form.append('extension', values.extension);
			// console.log(form);

			try {
				API.userFile(req1)
					.then((res) => {
						// console.log(res);
						if (res === 'success') {
							showNotification(
								<span className="d-flex align-items-center">
									<Icon icon="Info" size="lg" className="me-1" />
									<span>File uploaded successfully.</span>
								</span>,
								'',
								'success'
							);
							if (
								fileInputRef1.current &&
								fileInputRef2.current &&
								fileInputRef3.current
							) {
								fileInputRef1.current.value = '';
								fileInputRef2.current.value = '';
								fileInputRef3.current.value = '';
							}
						}
					})
					.catch((err) => {
						// console.log(err.error.messanger);

						showNotification('錯誤', err.error, 'danger');
					});
			} catch (error) {
				// console.log(error);
			}
		},
	});

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['25']);
	const { data: scoreData = { userScoreList: [] }, error = '' } = useSWR(
		// user.userId && '/board',
		'/board',
		() => API.getFrontendScoreList()
	);

	const filteredData = scoreData?.userScoreList?.filter((f) =>
		f?.userName?.toLowerCase().includes(formik.values.searchInput.toLowerCase())
	);

	const handleRowClick = (id: number) => {
		setOpenRowId(id === openRowId ? null : id);
	};

	const { items, requestSort, getClassNamesFor } = useSortableData(
		filteredData || []
	);
	// console.log(scoreData?.userScoreList?.length);

	return (
		<PageWrapper title="Upload / Leaderboard">
			<div className="container">
				<div className="row">
					<div className="col-12">
						{user.userToken && !user.userPermission && (
							<Card className="">
								<CardHeader>
									<CardLabel icon="DynamicFeed" color="dark">
										<CardTitle tag="h3">Upload</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<form noValidate>
										<InputGroup className="w-100 mb-3">
											<InputGroupText tag={'label'}>
												Camera_MOT19_A
											</InputGroupText>
											<Input
												type="file"
												autoComplete="file"
												ref={fileInputRef1}
												multiple
												onChange={(e: any) =>
													handleChangeFile1(e.target.files[0])
												}
											/>
										</InputGroup>
										<InputGroup className="w-100 mb-3">
											<InputGroupText tag={'label'}>
												Camera_MOT19_M
											</InputGroupText>
											<Input
												type="file"
												autoComplete="file"
												ref={fileInputRef2}
												multiple
												onChange={(e: any) =>
													handleChangeFile2(e.target.files[0])
												}
											/>
										</InputGroup>
										<InputGroup className="w-100 mb-3">
											<InputGroupText tag={'label'}>
												Camera_MOT19_N
											</InputGroupText>
											<Input
												type="file"
												autoComplete="file"
												ref={fileInputRef3}
												multiple
												onChange={(e: any) =>
													handleChangeFile3(e.target.files[0])
												}
											/>
										</InputGroup>

										<Button
											// isOutline
											className="w-100"
											color="dark"
											icon="CloudUpload"
											type="submit"
											onClick={formik.handleSubmit}
										>
											Send
										</Button>
									</form>
								</CardBody>
							</Card>
						)}
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon="Assessment" iconColor="dark">
									<CardTitle tag="h3">Leaderboard</CardTitle>
								</CardLabel>
								{/* <CardActions>
								<Button
									color="info"
									icon="CloudDownload"
									isLight
									tag="a"
									to="/somefile.txt"
									target="_blank"
									download
								>
									Export
								</Button>
							</CardActions> */}
							</CardHeader>
							<CardBody className="table-responsive">
								<table className="table table-modern">
									<thead>
										<tr>
											<th />
											<th
												onClick={() => requestSort('rank')}
												className="text-decoration-underline"
											>
												RANK
												<Icon
													size="lg"
													className={getClassNamesFor('rank')}
													icon="FilterList"
												/>
											</th>
											<th>User</th>
											<th
												onClick={() => requestSort('mota')}
												className="text-decoration-underline"
											>
												MOTA
												<Icon
													size="lg"
													className={getClassNamesFor('mota')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idf1')}
												className="text-decoration-underline"
											>
												IDF1
												<Icon
													size="lg"
													className={getClassNamesFor('idf1')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idp')}
												className="text-decoration-underline"
											>
												IDP{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('idp')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idr')}
												className="text-decoration-underline"
											>
												IDR{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('idr')}
													icon="FilterList"
												/>
											</th>

											<th
												onClick={() => requestSort('prcn')}
												className="text-decoration-underline"
											>
												PRCN{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('prcn')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('far')}
												className="text-decoration-underline"
											>
												FAR{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('far')}
													icon="FilterList"
												/>
											</th>

											<th
												onClick={() => requestSort('returnDate')}
												className="text-decoration-underline"
											>
												RETURN DATE
												<Icon
													size="lg"
													className={getClassNamesFor('returnDate')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('createDate')}
												className="text-decoration-underline"
											>
												UPLOAD DATE
												<Icon
													size="lg"
													className={getClassNamesFor('createDate')}
													icon="FilterList"
												/>
											</th>
										</tr>
									</thead>
									<tbody>
										{dataPagination(items, currentPage, perPage).map(
											(item, index) => (
												<React.Fragment key={index}>
													<tr>
														<td>
															<Button
																isOutline={!darkModeStatus}
																color="dark"
																isLight={darkModeStatus}
																className={classNames({
																	'border-light': !darkModeStatus,
																})}
																onClick={() => handleRowClick(item.scoreId)}
																icon="Info"
															/>
														</td>
														<td>
															<div
																className="ratio ratio-1x1 me-3"
																style={{ width: 48 }}
															>
																<div
																	className={`bg-l${
																		darkModeStatus ? 'o25' : '25'
																	}-${
																		item.rank <= 1
																			? 'success'
																			: item.rank <= 4
																			? 'info'
																			: item.rank <= 7
																			? 'warning'
																			: item.rank <= 10
																			? 'danger'
																			: 'dark'
																	} text-${
																		item.rank <= 1
																			? 'success'
																			: item.rank <= 4
																			? 'info'
																			: item.rank <= 7
																			? 'warning'
																			: item.rank <= 10
																			? 'danger'
																			: 'light'
																	} rounded-2 d-flex  h2 align-items-center justify-content-center`}
																>
																	<span className="fw-bold ">{item.rank}</span>
																</div>
															</div>
														</td>
														<td className="h5">
															<div className="d-flex ">
																<div className="flex-grow-1 d-flex align-items-center text-nowrap">
																	{item.userName}
																</div>
															</div>
														</td>
														<td className="h5">
															<Badge color="info">{numberUp(item.mota)}</Badge>
														</td>
														<td className="h5">
															<Badge color="success">
																{numberUp(item.idf1)}
															</Badge>
														</td>
														<td className="h5">
															<Badge color="dark">{numberUp(item.idp)}</Badge>
														</td>
														<td className="h5">
															<Badge color="primary">
																{numberUp(item.idr)}
															</Badge>
														</td>

														<td className="h5">
															<Badge color="warning">
																{numberUp(item.prcn)}
															</Badge>
														</td>
														<td className="h5">
															<Badge color="secondary">
																{numberUp(item.far)}
															</Badge>
														</td>

														<td>
															<div className="d-flex flex-column">
																{/* <span className="visually-hidden">
												{item.status.name}
											</span> */}

																<span>
																	{item.returnDate.split(' ')[0]}
																	{/* {moment(`${item.date} ${item.time}`).format(
													'MMM Do YYYY, h:mm a'
												)} */}
																</span>
																<span>{item.returnDate.split(' ')[1]}</span>
															</div>
														</td>

														<td>
															<div className="d-flex flex-column">
																{/* <span className="visually-hidden">
												{item.status.name}
											</span> */}

																<span>{item.createDate.split(' ')[0]}</span>
																<span>{item.createDate.split(' ')[1]}</span>
															</div>
														</td>
													</tr>
													<tr>
														{openRowId === item.scoreId && (
															<td colSpan={12} className="p-0">
																<table className="w-100 table-modern m-0 bg-white">
																	<thead>
																		<tr>
																			<th />

																			<th className="text-center">
																				GT
																				{/* Ground Truth */}
																			</th>
																			<th className="text-center">
																				MT
																				{/* Mostly Tracked */}
																			</th>
																			<th className="text-center">
																				PT
																				{/* Partially Tracked */}
																			</th>
																			<th className="text-center">
																				ML
																				{/* Mostly Lost */}
																			</th>

																			<th className="text-center">
																				FP
																				{/* False Positives */}
																			</th>
																			<th className="text-center">
																				FN
																				{/* False Negatives */}
																			</th>
																			<th className="text-center">
																				IDs
																				{/* Identity Switches */}
																			</th>
																			<th className="text-center">
																				FM
																				{/* Fragmentations */}
																			</th>

																			<th className="text-center">RCLL</th>
																			<th className="text-center">
																				MOTAL
																				{/* Multiple Object Tracking Accuracy Lower
																				Bound */}
																			</th>
																			<th className="text-center">
																				MOTP
																				{/* Multiple Object Tracking Precision */}
																			</th>

																			<th />
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			<td></td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.gt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.mt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.pt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.ml)}
																				</Badge>
																			</td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fp)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fn)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.ids)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fm)}
																				</Badge>
																			</td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.rcll)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.motal)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.motp)}
																				</Badge>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</td>
														)}
													</tr>
												</React.Fragment>
											)
										)}
										<tr>
											<td className="p-0 " colSpan={11}>
												{scoreData.userScoreList?.length === 0 ? (
													<div
														className="mx-auto d-flex justify-content-center "
														style={{
															height: 200,
															padding: 20,
														}}
													>
														<div className="d-flex flex-column  justify-content-center">
															<div style={{ margin: '0 auto' }}>
																<Icon
																	icon="Inbox"
																	color="dark"
																	className="me-1 h1"
																/>
															</div>
															No Rows To show
														</div>
													</div>
												) : null}
											</td>
										</tr>
									</tbody>
								</table>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export default Board;
