import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import Icon from '../../components/icon/Icon';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import Badge from '../../components/bootstrap/Badge';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import useDarkMode from '../../hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import { getFirstLetter, priceFormat } from '../../helpers/helpers';
import useSortableData from '../../hooks/useSortableData';
import * as API from '../../api/narlab';
import useSWR, { mutate } from 'swr';
import { ModelsGetPageListRes } from '../../swagger/narlab';
import showNotification from '../../components/extras/showNotification';
import AuthContext from '../../contexts/authContext';

const PageList = () => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [perPage, setPerPage] = useState<number>(PER_COUNT['25']);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const { data: pageData = { page: [] }, error = '' } =
		useSWR<ModelsGetPageListRes>(user.userId && '/page', () =>
			API.getListDetail(user.userId || '')
		);

	const formik = useFormik({
		initialValues: {
			searchInput: '',
			minPrice: '',
			maxPrice: '',
		},

		onSubmit: (values) => {},
	});

	const filteredData = pageData?.page?.filter((f) =>
		f?.pageName?.toLowerCase().includes(formik.values.searchInput.toLowerCase())
	);

	function handleClickDelete(pageId: string, pageName: string) {
		dispatch({
			type: 'CONFIRM_SHOW',
			payload: {
				title: '刪除文章',
				content: `確定要刪除 ${pageName} 的文章嗎?`,
				func: () => {
					API.pageDelete(pageId, user.userId || '')
						.then((res) => {
							if (res === 'success') {
								showNotification(
									<span className="d-flex align-items-center">
										<Icon icon="Delete" size="lg" className="me-1" />
										<span>已刪除成功。</span>
									</span>,
									'The  web page have been deleted.'
								);
								mutate('/page');
							}
						})

						.catch((err) => {
							showNotification('錯誤', err.error, 'danger');
						});
				},
			},
		});
	}

	const { items, requestSort, getClassNamesFor } = useSortableData(
		filteredData || []
	);

	return (
		<PageWrapper title="管理文章">
			<div className="mt-3">
				<SubHeader>
					<SubHeaderLeft>
						<label
							className="border-0 bg-transparent cursor-pointer me-0 "
							htmlFor="searchInput"
						>
							<Icon icon="Search" size="2x" color="dark" />
						</label>
						<Input
							id="searchInput"
							type="search"
							className="border-0 shadow-none bg-transparent"
							placeholder="搜尋文章...."
							onChange={formik.handleChange}
							value={formik.values.searchInput}
						/>
					</SubHeaderLeft>
					<SubHeaderRight>
						<SubheaderSeparator />
						<Button
							color="dark"
							onClick={() => navigate('/admin/manage')}
							icon="NoteAdd"
							// isLight
						>
							新增文章
						</Button>
					</SubHeaderRight>
				</SubHeader>
			</div>
			<Page>
				<div className="row h-100">
					<div className="col-12">
						<Card stretch>
							<CardBody isScrollable className="table-responsive">
								<table className="table table-modern table-hover">
									<thead>
										<tr>
											<th>文章名稱</th>
											<th style={{ paddingLeft: 25 }}>識別碼</th>
											<th className="text-center">綁定導覽列</th>
											<th className="text-center">文章狀態</th>
											<th onClick={() => requestSort('updateDate')}>
												編輯日期
												<Icon
													size="lg"
													className={getClassNamesFor('updateDate')}
													icon="FilterList"
												/>
											</th>
											<td />
										</tr>
									</thead>
									<tbody>
										{dataPagination(items, currentPage, perPage).map(
											(i, index) => (
												<tr key={index}>
													<td>
														<div className="d-flex align-items-center">
															<div className="flex-shrink-0">
																<div
																	className="ratio ratio-1x1 me-3"
																	style={{ width: 48 }}
																>
																	<div
																		className={`bg-light text-dark rounded-2 d-flex h2 align-items-center justify-content-center`}
																	>
																		<span className="fw-bold">
																			{getFirstLetter(i.pageButton)}
																		</span>
																	</div>
																</div>
															</div>
															<div className="flex-grow-1">
																<div className="fs-5 fw-bold">{i.pageName}</div>
																<div className="text-muted">
																	<Badge color="dark">
																		{/* <Icon icon="Label" /> */}
																		{i.pageButton}
																	</Badge>
																</div>
															</div>
														</div>
													</td>
													<td>
														<Button
															isLink
															color="light"
															icon="Link"
															className="text-lowercase"
															tag="a"
															size="sm"
															target="_blank"
															// href={`mailto:${i.email}`}
															href={`https://dakors.com/${i.pagePath}`}
														>
															{/* {i.email} */}
															https://dakors.com/{i.pagePath}
														</Button>
													</td>

													<td className="h4 text-center">
														<Badge color={i.pageNavName ? 'info' : 'danger'}>
															{i.pageNavName ? `${i.pageNavName}` : '尚無'}
														</Badge>
													</td>
													<td className="h4 text-center">
														<Badge
															isLight
															color={i.pageStatus === '1' ? 'info' : 'danger'}
														>
															{i.pageStatus === '1' ? '開啟' : '關閉'}
														</Badge>
													</td>
													<td>
														{/* <div>{i.membershipDate.format('ll')}</div> */}

														<div>
															<small className="text-muted">
																{priceFormat(i.updateDate)}
																{/* {i.updateDate.fromNow()} */}
															</small>
														</div>
													</td>
													<td>
														<Dropdown className="d-inline color-dark">
															<DropdownToggle hasIcon={false}>
																<Button color={themeStatus} icon="MoreHoriz" />
															</DropdownToggle>
															<DropdownMenu isAlignmentEnd>
																<DropdownItem>
																	<Button
																		icon="Edit"
																		onClick={() =>
																			navigate(`/admin/manage/${i.pageId}`)
																		}
																	>
																		編輯
																	</Button>
																</DropdownItem>
																<DropdownItem>
																	<Button
																		icon="Delete"
																		onClick={() =>
																			handleClickDelete(i.pageId, i.pageName)
																		}
																	>
																		刪除
																	</Button>
																</DropdownItem>
															</DropdownMenu>
														</Dropdown>
													</td>
												</tr>
											)
										)}
										<tr>
											<td className="p-0 " colSpan={11}>
												{pageData.page?.length ? null : (
													<div
														className="mx-auto d-flex justify-content-center "
														style={{
															height: 200,
															padding: 20,
														}}
													>
														<div className="d-flex flex-column  justify-content-center">
															<div style={{ margin: '0 auto' }}>
																<Icon
																	icon="Inbox"
																	color="dark"
																	className="me-1 h1"
																/>
															</div>
															尚未有資料填入
														</div>
													</div>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default PageList;
