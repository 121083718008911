import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import Button from '../../components/bootstrap/Button';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalHeader,
} from '../../components/bootstrap/Modal';
import Timeline, { TimelineItem } from '../../components/extras/Timeline';
import Icon from '../../components/icon/Icon';
import { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import useSortableData from '../../hooks/useSortableData';
import useDarkMode from '../../hooks/useDarkMode';
import Badge from '../../components/bootstrap/Badge';
import AuthContext from '../../contexts/authContext';

import * as API from '../../api/narlab';
import { ModelsUserScoreLog } from '../../swagger/narlab';

const History = () => {
	const { darkModeStatus } = useDarkMode();
	const [openRowId, setOpenRowId] = useState<number | null>(null);
	const { user } = useContext(AuthContext);
	const [historyModalDetailStatus, setHistoryModalDetailStatus] = useState<
		string | null
	>(null);
	const [scoreLog, setScoreLog] = useState<ModelsUserScoreLog[] | []>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['25']);

	const { items, requestSort, getClassNamesFor } = useSortableData(
		user.userScoreList || []
	);
	// console.log(user.userScoreList);
	function numberUp(e: number) {
		const roundedNum: number = Math.ceil(e * 100) / 100;
		return roundedNum;
	}

	const handleRowClick = (id: number) => {
		setOpenRowId(id === openRowId ? null : id);
	};

	function handleClickHistory(scoreId: string) {
		setHistoryModalDetailStatus(scoreId);
		API.getUserFileHistory(scoreId || '').then((res) => {
			if (res.userScoreLogList && res.userScoreLogList.length > 0) {
				setScoreLog(res.userScoreLogList);
			}
		});
	}

	return (
		<PageWrapper title="History">
			<div className="container">
				<div className="row">
					<div className="col-12">
						{user.userToken && !user.userPermission && (
							<Card>
								<CardHeader borderSize={1}>
									<CardLabel icon="HistoryToggleOff" iconColor="dark">
										<CardTitle tag="h3">History</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className="table-responsive">
									<table className="table table-modern">
										<thead>
											<tr>
												<th />
												<th
													onClick={() => requestSort('rank')}
													className="text-decoration-underline"
												>
													RANK
													<Icon
														size="lg"
														className={getClassNamesFor('rank')}
														icon="FilterList"
													/>
												</th>

												<th
													onClick={() => requestSort('mota')}
													className="text-decoration-underline"
												>
													MOTA
													<Icon
														size="lg"
														className={getClassNamesFor('mota')}
														icon="FilterList"
													/>
												</th>
												<th
													onClick={() => requestSort('idf1')}
													className="text-decoration-underline"
												>
													IDF1
													<Icon
														size="lg"
														className={getClassNamesFor('idf1')}
														icon="FilterList"
													/>
												</th>
												<th
													onClick={() => requestSort('idp')}
													className="text-decoration-underline"
												>
													IDP{' '}
													<Icon
														size="lg"
														className={getClassNamesFor('idp')}
														icon="FilterList"
													/>
												</th>
												<th
													onClick={() => requestSort('idr')}
													className="text-decoration-underline"
												>
													IDR{' '}
													<Icon
														size="lg"
														className={getClassNamesFor('idr')}
														icon="FilterList"
													/>
												</th>

												<th
													onClick={() => requestSort('prcn')}
													className="text-decoration-underline"
												>
													PRCN{' '}
													<Icon
														size="lg"
														className={getClassNamesFor('prcn')}
														icon="FilterList"
													/>
												</th>
												<th
													onClick={() => requestSort('far')}
													className="text-decoration-underline"
												>
													FAR{' '}
													<Icon
														size="lg"
														className={getClassNamesFor('far')}
														icon="FilterList"
													/>
												</th>

												<th
													onClick={() => requestSort('returnDate')}
													className="text-decoration-underline"
												>
													RETURN DATE
													<Icon
														size="lg"
														className={getClassNamesFor('returnDate')}
														icon="FilterList"
													/>
												</th>
												<th
													onClick={() => requestSort('createDate')}
													className="text-decoration-underline"
												>
													UPLOAD DATE
													<Icon
														size="lg"
														className={getClassNamesFor('createDate')}
														icon="FilterList"
													/>
												</th>
												<th />
											</tr>
										</thead>
										<tbody>
											{dataPagination(items, currentPage, perPage).map(
												(item, index) => (
													<React.Fragment key={index}>
														<tr>
															<td>
																<Button
																	isOutline={!darkModeStatus}
																	color="dark"
																	isLight={darkModeStatus}
																	className={classNames({
																		'border-light': !darkModeStatus,
																	})}
																	onClick={() => handleRowClick(item.scoreId)}
																	icon="Info"
																/>
															</td>
															<td>
																<div
																	className="ratio ratio-1x1 me-3"
																	style={{ width: 48 }}
																>
																	<div
																		className={`bg-l${
																			darkModeStatus ? 'o25' : '25'
																		}-${
																			item.rank <= 1
																				? 'success'
																				: item.rank <= 4
																				? 'info'
																				: item.rank <= 7
																				? 'warning'
																				: item.rank <= 10
																				? 'danger'
																				: 'dark'
																		} text-${
																			item.rank <= 1
																				? 'success'
																				: item.rank <= 4
																				? 'info'
																				: item.rank <= 7
																				? 'warning'
																				: item.rank <= 10
																				? 'danger'
																				: 'light'
																		} rounded-2 d-flex  h2 align-items-center justify-content-center`}
																	>
																		<span className="fw-bold ">
																			{item.rank}
																		</span>
																	</div>
																</div>
															</td>

															<td className="h5">
																<Badge color="info">
																	{numberUp(item.mota)}
																</Badge>
															</td>
															<td className="h5">
																<Badge color="success">
																	{numberUp(item.idf1)}
																</Badge>
															</td>
															<td className="h5">
																<Badge color="dark">{numberUp(item.idp)}</Badge>
															</td>
															<td className="h5">
																<Badge color="primary">
																	{numberUp(item.idr)}
																</Badge>
															</td>

															<td className="h5">
																<Badge color="warning">
																	{numberUp(item.prcn)}
																</Badge>
															</td>
															<td className="h5">
																<Badge color="secondary">
																	{numberUp(item.far)}
																</Badge>
															</td>

															<td>
																<div className="d-flex flex-column">
																	{/* <span className="visually-hidden">
												{item.status.name}
											</span> */}
																	<span>{item.returnDate.split(' ')[0]}</span>
																	<span>{item.returnDate.split(' ')[1]}</span>
																</div>
															</td>

															<td>
																<div className="d-flex flex-column">
																	{/* <span className="visually-hidden">
												{item.status.name}
											</span> */}

																	<span>{item.createDate.split(' ')[0]}</span>
																	<span>{item.createDate.split(' ')[1]}</span>
																</div>
															</td>

															<td>
																<Button
																	isOutline={!darkModeStatus}
																	color="dark"
																	isLight={darkModeStatus}
																	className={classNames({
																		'border-light': !darkModeStatus,
																	})}
																	onClick={() =>
																		handleClickHistory(item.scoreId)
																	}
																	icon="HistoryToggleOff"
																>
																	History Record
																</Button>
															</td>
														</tr>
														<tr>
															{openRowId === item.scoreId && (
																<td colSpan={12} className="p-0">
																	<table className="w-100 table-modern m-0">
																		<thead>
																			<tr>
																				<th />

																				<th className="text-center">
																					GT
																					{/* Ground Truth */}
																				</th>
																				<th className="text-center">
																					MT
																					{/* Mostly Tracked */}
																				</th>
																				<th className="text-center">
																					PT
																					{/* Partially Tracked */}
																				</th>
																				<th className="text-center">
																					ML
																					{/* Mostly Lost */}
																				</th>

																				<th className="text-center">
																					FP
																					{/* False Positives */}
																				</th>
																				<th className="text-center">
																					FN
																					{/* False Negatives */}
																				</th>
																				<th className="text-center">
																					IDs
																					{/* Identity Switches */}
																				</th>
																				<th className="text-center">
																					FM
																					{/* Fragmentations */}
																				</th>

																				<th className="text-center">RCLL</th>
																				<th className="text-center">
																					MOTAL
																					{/* Multiple Object Tracking Accuracy Lower
																				Bound */}
																				</th>
																				<th className="text-center">
																					MOTP
																					{/* Multiple Object Tracking Precision */}
																				</th>

																				<th />
																			</tr>
																		</thead>
																		<tbody>
																			<tr>
																				<td></td>

																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.gt)}
																					</Badge>
																				</td>
																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.mt)}
																					</Badge>
																				</td>
																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.pt)}
																					</Badge>
																				</td>
																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.ml)}
																					</Badge>
																				</td>

																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.fp)}
																					</Badge>
																				</td>
																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.fn)}
																					</Badge>
																				</td>
																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.ids)}
																					</Badge>
																				</td>
																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.fm)}
																					</Badge>
																				</td>

																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.rcll)}
																					</Badge>
																				</td>
																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.motal)}
																					</Badge>
																				</td>
																				<td className="h5 text-center">
																					<Badge color="dark">
																						{numberUp(item.motp)}
																					</Badge>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</td>
															)}
														</tr>
													</React.Fragment>
												)
											)}

											<tr>
												<td className="p-0 " colSpan={11}>
													{user.userScoreList?.length ? null : (
														<div
															className="mx-auto d-flex justify-content-center "
															style={{
																height: 200,
																padding: 20,
															}}
														>
															<div className="d-flex flex-column  justify-content-center">
																<div style={{ margin: '0 auto' }}>
																	<Icon
																		icon="Inbox"
																		color="dark"
																		className="me-1 h1"
																	/>
																</div>
																No Rows To show
															</div>
														</div>
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</CardBody>
							</Card>
						)}
					</div>
				</div>
			</div>
			<Modal
				setIsOpen={setHistoryModalDetailStatus}
				isOpen={historyModalDetailStatus ? true : false}
				size="lg"
				data-tour="mail-app-modal"
			>
				<ModalHeader className="px-4" setIsOpen={setHistoryModalDetailStatus}>
					<CardLabel icon="HistoryToggleOff" iconColor="dark">
						<CardTitle>History Record</CardTitle>
					</CardLabel>
				</ModalHeader>

				<ModalBody className="px-4">
					<CardBody>
						<Timeline>
							{scoreLog?.map((item, index) => (
								<TimelineItem label={''} color="success" key={index}>
									<Card className="light">
										<CardHeader className="light" borderSize={3}>
											<CardLabel icon="AccessTime" iconColor={'dark'}>
												<CardTitle>{item.createDate}</CardTitle>
												<CardLabel>Upload time</CardLabel>
											</CardLabel>
										</CardHeader>
										<CardBody>
											<div className="light" style={{ whiteSpace: 'pre-line' }}>
												Result：{item.logAction}
											</div>
										</CardBody>
									</Card>
								</TimelineItem>
							))}
						</Timeline>
					</CardBody>
				</ModalBody>
			</Modal>
		</PageWrapper>
	);
};

export default History;
