import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import Button from '../bootstrap/Button';
import Modal, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '../bootstrap/Modal';

const ConfirmModal = () => {
	const { show, content, title, func } = useSelector(
		(state: RootState) => state.confirm
	);
	const dispatch = useDispatch();

	function handleSetIsOpen(state: any) {
		if (state === false) {
			dispatch({
				type: 'CONFIRM_HIDE',
			});
		}
	}

	return (
		<Modal
			id="ConfirmModal"
			isOpen={show}
			setIsOpen={handleSetIsOpen}
			isCentered
			size="sm"
		>
			<ModalHeader>
				<ModalTitle id="title">{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>{content}</ModalBody>
			<ModalFooter>
				<div className="row g-3">
					<div className="col">
						<Button
							color="primary"
							onClick={() => dispatch({ type: 'CONFIRM_ONCLICK' })}
						>
							Confirm
						</Button>
					</div>
					<div className="col">
						<Button color="light" onClick={() => handleSetIsOpen(false)}>
							Cancel
						</Button>
					</div>
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default ConfirmModal;
