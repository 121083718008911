import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import Button from '../../components/bootstrap/Button';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Timeline, { TimelineItem } from '../../components/extras/Timeline';
import Icon from '../../components/icon/Icon';
import { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import useSortableData from '../../hooks/useSortableData';
import useDarkMode from '../../hooks/useDarkMode';
import { useFormik } from 'formik';
import Badge from '../../components/bootstrap/Badge';
import AuthContext from '../../contexts/authContext';
import * as API from '../../api/narlab';
import useSWR, { mutate } from 'swr';
import { useDispatch } from 'react-redux';
import showNotification from '../../components/extras/showNotification';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../components/bootstrap/Modal';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { ModelsUserScoreLog } from '../../swagger/narlab';

const ManageBoard = () => {
	const dispatch = useDispatch();
	const { user } = useContext(AuthContext);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [manageModalStatus, setManageModalStatus] = useState<string | null>(
		null
	);

	const [historyModalStatus, setHistoryModalStatus] = useState<string | null>(
		null
	);

	const { data: scoreData = { userScoreList: [] }, error = '' } = useSWR(
		user.userId && '/board',
		() => API.getScoreList({ execManagerId: user.userId || '' })
	);

	// BEGIN :: Upcoming Events
	const [upcomingEventsInfoOffcanvas, setUpcomingEventsInfoOffcanvas] =
		useState(false);
	const handleUpcomingDetails = () => {
		setUpcomingEventsInfoOffcanvas(!upcomingEventsInfoOffcanvas);
	};

	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] =
		useState(false);

	// END :: Upcoming Events

	const [isOpen, setIsOpen] = useState(false);
	const [openRowId, setOpenRowId] = useState<number | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['25']);
	const [scoreLog, setScoreLog] = useState<ModelsUserScoreLog[] | []>([]);

	const formik = useFormik({
		initialValues: {
			searchInput: '',
			scoreId: '',
			userName: '',
			returnDate: '',
			createDate: '',
			updateDate: '',
			updateUser: '',
			far: 0,
			fm: 0,
			fn: 0,
			fp: 0,
			gt: 0,
			idf1: 0,
			idp: 0,
			idr: 0,
			ids: 0,
			ml: 0,
			mota: 0,
			motal: 0,
			motp: 0,
			mt: 0,
			prcn: 0,
			pt: 0,
			rcll: 0,
		},
		validate: (values) => {
			const errors: {
				userName?: string;
				returnDate?: string;
				createDate?: string;
				far?: string;
				fm?: string;
				fn?: string;
				fp?: string;
				gt?: string;
				idf1?: string;
				idp?: string;
				idr?: string;
				ids?: string;
				ml?: string;
				mota?: string;
				motal?: string;
				motp?: string;
				mt?: string;
				prcn?: string;
				pt?: string;
				rcll?: string;
			} = {
				userName: undefined,
				returnDate: undefined,
				createDate: undefined,
				far: undefined,
				fm: undefined,
				fn: undefined,
				fp: undefined,
				gt: undefined,
				idf1: undefined,
				idp: undefined,
				idr: undefined,
				ids: undefined,
				ml: undefined,
				mota: undefined,
				motal: undefined,
				motp: undefined,
				mt: undefined,
				prcn: undefined,
				pt: undefined,
				rcll: undefined,
			};
			if (!values.userName) {
				errors.userName = '請輸入使用者名稱';
			}
			// if (!values.returnDate) {
			// 	errors.returnDate = '請輸入回傳時間';
			// }
			// if (!values.createDate) {
			// 	errors.createDate = '請輸入上傳時間';
			// }
			if (!values.far) {
				errors.far = '請輸入FAR';
			}
			if (!values.fm) {
				errors.fm = '請輸入FM';
			}
			if (!values.fn) {
				errors.fn = '請輸入FN';
			}
			if (!values.fp) {
				errors.fp = '請輸入FP';
			}
			if (!values.gt) {
				errors.gt = '請輸入GT';
			}
			if (!values.idf1) {
				errors.idf1 = '請輸入IDF1';
			}
			if (!values.idp) {
				errors.idp = '請輸入IDP';
			}
			if (!values.idr) {
				errors.idr = '請輸入IDR';
			}
			if (!values.ids) {
				errors.ids = '請輸入IDS';
			}
			if (!values.ml) {
				errors.ml = '請輸入ML';
			}
			if (!values.mota) {
				errors.mota = '請輸入MOTA';
			}
			if (!values.motp) {
				errors.motp = '請輸入MOTP';
			}
			if (!values.mt) {
				errors.mt = '請輸入MT';
			}
			if (!values.prcn) {
				errors.prcn = '請輸入PRCN';
			}
			if (!values.pt) {
				errors.pt = '請輸入PT';
			}
			if (!values.rcll) {
				errors.rcll = '請輸入RCLL';
			}

			//錯誤判斷
			const boardErrors = Object.values(errors).reduce((map, current) => {
				if (current) {
					return true;
				}
				return map;
			}, false);

			if (!boardErrors) {
				return {};
			}
			return errors;
		},

		onSubmit: (values) => {
			// console.log(values);
			API.scoreUpdate({
				execManagerId: user.userId || '',
				scoreId: values.scoreId || '',
				far: values.far || 0,
				fm: values.fm || 0,
				fn: values.fn || 0,
				fp: values.fp || 0,
				gt: values.gt || 0,
				idf1: values.idf1 || 0,
				idp: values.idp || 0,
				idr: values.idr || 0,
				ids: values.ids || 0,
				ml: values.ml || 0,
				mota: values.mota || 0,
				motal: values.motal || 0,
				motp: values.motp || 0,
				mt: values.mt || 0,
				prcn: values.prcn || 0,
				pt: values.pt || 0,
				rcll: values.rcll || 0,
			})
				.then((res) => {
					// console.log(res);
					if (res === 'success') {
						showNotification(
							<span className="d-flex align-items-center">
								<Icon icon="Info" size="lg" className="me-1" />
								<span>已成功編輯的成績。</span>
							</span>,

							'success'
						);
						mutate('/board');
						setManageModalStatus('');
					}
				})
				.catch((err) => {
					// console.log(err.error.messanger);
					showNotification('錯誤', err.error, 'danger');
				});
		},
	});

	const filteredData = scoreData?.userScoreList?.filter((f) =>
		f?.userName?.toLowerCase().includes(formik.values.searchInput.toLowerCase())
	);

	const handleRowClick = (id: number) => {
		setOpenRowId(id === openRowId ? null : id);
	};

	function numberUp(e: number) {
		const roundedNum: number = Math.ceil(e * 100) / 100;
		return roundedNum;
	}

	const { items, requestSort, getClassNamesFor } = useSortableData(
		filteredData || []
	);

	function handleClickDelete(scoreId: string, userName: string) {
		dispatch({
			type: 'CONFIRM_SHOW',
			payload: {
				title: '刪除排行榜中的使用者',
				content: `確定要刪除 ${userName} 使用者嗎?`,
				func: () => {
					API.scoreDelete({ execManagerId: user.userId || '', scoreId })
						.then((res) => {
							// console.log(res);
							if (res === 'success') {
								showNotification(
									<span className="d-flex align-items-center">
										<Icon icon="Delete" size="lg" className="me-1" />
										<span>已刪除成功。</span>
									</span>,
									'The user have been deleted.'
								);
								mutate('/board');
							}
						})

						.catch((err) => {
							// console.log(err.error);
							showNotification('錯誤', err.error, 'danger');
						});
				},
			},
		});
	}

	function handleClickHistory(scoreId: string) {
		setHistoryModalStatus(scoreId);
		API.getUserScoreLog(scoreId, user.userId || '').then((res) => {
			if (res.userScoreLogList && res.userScoreLogList.length > 0) {
				setScoreLog(res.userScoreLogList);
			}
		});
	}

	function handleClickEdit(userName: string, scoreId: string) {
		setManageModalStatus('true');
		API.getUserScore(scoreId, user.userId || '')
			.then((res) => {
				formik.setValues({
					searchInput: '',
					createDate: res.createDate || '',
					userName: userName || '',
					far: res.far || 0,
					fm: res.fm || 0,
					fn: res.fn || 0,
					fp: res.fp || 0,
					gt: res.gt || 0,
					idf1: res.idf1 || 0,
					idp: res.idp || 0,
					idr: res.idr || 0,
					ids: res.ids || 0,
					ml: res.ml || 0,
					mota: res.mota || 0,
					motal: res.motal || 0,
					motp: res.motp || 0,
					mt: res.mt || 0,
					prcn: res.prcn || 0,
					pt: res.pt || 0,
					rcll: res.rcll || 0,
					returnDate: res.returnDate || '',
					scoreId: scoreId,
					updateDate: '',
					updateUser: '',
					// userScoreLogList:[],
				});

				setManageModalStatus('false');
			})
			.catch((err) => {
				showNotification('錯誤', err.error, 'danger');
			});
	}

	return (
		<PageWrapper title="管理排行榜">
			<Page>
				<div className="row  h-100">
					<div className="col-12 mt-3">
						<Card stretch>
							<CardHeader borderSize={1}>
								<CardLabel icon="Assessment" iconColor="dark">
									<CardTitle>管理排行榜</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable className="table-responsive">
								<table className="table table-modern m-0">
									<thead>
										<tr>
											<th />
											<th
												onClick={() => requestSort('rank')}
												className="text-decoration-underline"
											>
												排行榜
												<Icon
													size="lg"
													className={getClassNamesFor('rank')}
													icon="FilterList"
												/>
											</th>
											<th>使用者名稱</th>
											<th
												onClick={() => requestSort('mota')}
												className="text-decoration-underline"
											>
												MOTA
												<Icon
													size="lg"
													className={getClassNamesFor('mota')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idf1')}
												className="text-decoration-underline"
											>
												IDF1
												<Icon
													size="lg"
													className={getClassNamesFor('idf1')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idp')}
												className="text-decoration-underline"
											>
												IDP{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('idp')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idr')}
												className="text-decoration-underline"
											>
												IDR{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('idr')}
													icon="FilterList"
												/>
											</th>

											<th
												onClick={() => requestSort('prcn')}
												className="text-decoration-underline"
											>
												PRCN{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('prcn')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('far')}
												className="text-decoration-underline"
											>
												FAR{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('far')}
													icon="FilterList"
												/>
											</th>

											<th
												onClick={() => requestSort('returnDate')}
												className="text-decoration-underline"
											>
												回傳時間
												<Icon
													size="lg"
													className={getClassNamesFor('returnDate')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('createDate')}
												className="text-decoration-underline"
											>
												上傳時間
												<Icon
													size="lg"
													className={getClassNamesFor('createDate')}
													icon="FilterList"
												/>
											</th>
											<th />
										</tr>
									</thead>

									<tbody>
										{dataPagination(items, currentPage, perPage).map(
											(item, index) => (
												<React.Fragment key={index}>
													<tr>
														<td>
															<Button
																isOutline={!darkModeStatus}
																color="dark"
																isLight={darkModeStatus}
																className={classNames({
																	'border-light': !darkModeStatus,
																})}
																onClick={() => handleRowClick(item.scoreId)}
																icon="Info"
															/>
														</td>
														<td>
															<div
																className="ratio ratio-1x1 me-3"
																style={{ width: 48 }}
															>
																<div
																	className={`bg-l${
																		darkModeStatus ? 'o25' : '25'
																	}-${
																		item.rank <= 1
																			? 'success'
																			: item.rank <= 4
																			? 'info'
																			: item.rank <= 7
																			? 'warning'
																			: item.rank <= 10
																			? 'danger'
																			: 'dark'
																	} text-${
																		item.rank <= 1
																			? 'success'
																			: item.rank <= 4
																			? 'info'
																			: item.rank <= 7
																			? 'warning'
																			: item.rank <= 10
																			? 'danger'
																			: 'light'
																	} rounded-2 d-flex  h2 align-items-center justify-content-center`}
																>
																	<span className="fw-bold ">
																		{numberUp(item.rank)}
																	</span>
																</div>
															</div>
														</td>
														<td className="h5">
															<div className="d-flex ">
																<div className="flex-grow-1 d-flex align-items-center text-nowrap">
																	{item.userName}
																</div>
															</div>
														</td>
														<td className="h5">
															<Badge color="info">{numberUp(item.mota)}</Badge>
														</td>
														<td className="h5">
															<Badge color="success">
																{numberUp(item.idf1)}
															</Badge>
														</td>
														<td className="h5">
															<Badge color="dark">{numberUp(item.idp)}</Badge>
														</td>
														<td className="h5">
															<Badge color="primary">
																{numberUp(item.idr)}
															</Badge>
														</td>

														<td className="h5">
															<Badge color="warning">
																{numberUp(item.prcn)}
															</Badge>
														</td>
														<td className="h5">
															<Badge color="secondary">
																{numberUp(item.far)}
															</Badge>
														</td>

														<td>
															<div className="d-flex flex-column">
																{/* <span className="visually-hidden">
															{item.status.name}
														</span> */}

																<span>{item.returnDate.split(' ')[0]}</span>
																<span>{item.returnDate.split(' ')[1]}</span>
															</div>
														</td>

														<td>
															<div className="d-flex flex-column">
																{/* <span className="visually-hidden">
															{item.status.name}
														</span> */}

																<span>{item.createDate.split(' ')[0]}</span>
																<span>{item.createDate.split(' ')[1]}</span>
															</div>
														</td>
														<td>
															<Dropdown
																className="d-inline color-dark"
																direction="up"
															>
																<DropdownToggle hasIcon={false}>
																	<Button
																		color={themeStatus}
																		icon="MoreHoriz"
																	/>
																</DropdownToggle>
																<DropdownMenu isAlignmentEnd>
																	<DropdownItem>
																		<Button
																			icon="HistoryToggleOff"
																			// onClick={() =>
																			// 	navigate(`/admin/manage/${i.pageId}`)
																			// }
																			onClick={() =>
																				handleClickHistory(item.scoreId)
																			}
																		>
																			上傳歷程
																		</Button>
																	</DropdownItem>
																	<DropdownItem>
																		<Button
																			icon="Edit"
																			// onClick={() =>
																			// 	navigate(`/admin/manage/${i.pageId}`)
																			// }
																			onClick={() => {
																				handleClickEdit(
																					item.userName,
																					item.scoreId
																				);
																			}}
																		>
																			編輯
																		</Button>
																	</DropdownItem>

																	<DropdownItem>
																		<Button
																			icon="Delete"
																			onClick={() =>
																				handleClickDelete(
																					item.scorId,
																					item.userName
																				)
																			}
																		>
																			刪除
																		</Button>
																	</DropdownItem>
																</DropdownMenu>
															</Dropdown>
														</td>
													</tr>
													<tr>
														{openRowId === item.scoreId && (
															<td colSpan={12} className="p-0">
																<table className="w-100 table-modern m-0">
																	<thead>
																		<tr>
																			<th />

																			<th className="text-center">
																				GT
																				{/* Ground Truth */}
																			</th>
																			<th className="text-center">
																				MT
																				{/* Mostly Tracked */}
																			</th>
																			<th className="text-center">
																				PT
																				{/* Partially Tracked */}
																			</th>
																			<th className="text-center">
																				ML
																				{/* Mostly Lost */}
																			</th>

																			<th className="text-center">
																				FP
																				{/* False Positives */}
																			</th>
																			<th className="text-center">
																				FN
																				{/* False Negatives */}
																			</th>
																			<th className="text-center">
																				IDs
																				{/* Identity Switches */}
																			</th>
																			<th className="text-center">
																				FM
																				{/* Fragmentations */}
																			</th>

																			<th className="text-center">RCLL</th>
																			<th className="text-center">
																				MOTAL
																				{/* Multiple Object Tracking Accuracy Lower
																				Bound */}
																			</th>
																			<th className="text-center">
																				MOTP
																				{/* Multiple Object Tracking Precision */}
																			</th>

																			<th />
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			<td></td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.gt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.mt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.pt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.ml)}
																				</Badge>
																			</td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fp)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fn)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.ids)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fm)}
																				</Badge>
																			</td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.rcll)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.motal)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.motp)}
																				</Badge>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</td>
														)}
													</tr>
												</React.Fragment>
											)
										)}
										<tr>
											<td className="p-0 " colSpan={11}>
												{scoreData.userScoreList?.length === 0 ? (
													<div
														className="mx-auto d-flex justify-content-center "
														style={{
															height: 200,
															padding: 20,
														}}
													>
														<div className="d-flex flex-column  justify-content-center">
															<div style={{ margin: '0 auto' }}>
																<Icon
																	icon="Inbox"
																	color="dark"
																	className="me-1 h1"
																/>
															</div>
															尚未有資料填入
														</div>
													</div>
												) : null}
											</td>
										</tr>
									</tbody>
								</table>
							</CardBody>
						</Card>

						{/* 上傳紀錄 */}
						<Modal
							setIsOpen={setHistoryModalStatus}
							isOpen={historyModalStatus ? true : false}
							size="lg"
							data-tour="mail-app-modal"
						>
							<ModalHeader className="px-4" setIsOpen={setHistoryModalStatus}>
								<CardLabel icon="HistoryToggleOff" iconColor="dark">
									<CardTitle>上傳歷程</CardTitle>
								</CardLabel>
							</ModalHeader>

							<ModalBody className="px-4">
								<CardBody>
									<Timeline>
										{scoreLog?.map((item, index) => (
											<TimelineItem label={''} color="success" key={index}>
												<Card className="light">
													<CardHeader className="light" borderSize={3}>
														<CardLabel icon="AccessTime" iconColor={'dark'}>
															<CardTitle>{item.createDate}</CardTitle>
															<CardLabel>上傳時間</CardLabel>
														</CardLabel>
													</CardHeader>
													<CardBody>
														<div
															className="light"
															style={{ whiteSpace: 'pre-line' }}
														>
															結果：{item.logAction}
														</div>
													</CardBody>
												</Card>
											</TimelineItem>
										))}
									</Timeline>
								</CardBody>
							</ModalBody>
						</Modal>
						{/* 編輯排行榜*/}
						<Modal
							setIsOpen={setManageModalStatus}
							isOpen={manageModalStatus ? true : false}
							size="lg"
							data-tour="mail-app-modal"
						>
							<ModalHeader className="px-4" setIsOpen={setManageModalStatus}>
								<CardLabel icon="Person" iconColor="info">
									<CardTitle>{formik.values.userName}</CardTitle>
								</CardLabel>
							</ModalHeader>

							<ModalBody className="px-4">
								<form className="row g-4">
									<div className="col-2">
										<FormGroup id="far" isFloating label={'FAR'}>
											<Input
												type="number"
												autoComplete="far"
												value={formik.values.far}
												isTouched={formik.touched.far}
												invalidFeedback={formik.errors.far}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="fm" isFloating label={'FM'}>
											<Input
												type="number"
												autoComplete="fm"
												value={formik.values.fm}
												isTouched={formik.touched.fm}
												invalidFeedback={formik.errors.fm}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="fn" isFloating label={'FN'}>
											<Input
												type="number"
												autoComplete="fn"
												value={formik.values.fn}
												isTouched={formik.touched.fn}
												invalidFeedback={formik.errors.fn}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="fp" isFloating label={'FP'}>
											<Input
												type="number"
												autoComplete="fp"
												value={formik.values.fp}
												isTouched={formik.touched.fp}
												invalidFeedback={formik.errors.fp}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="gt" isFloating label={'GT'}>
											<Input
												type="number"
												autoComplete="gt"
												value={formik.values.gt}
												isTouched={formik.touched.gt}
												invalidFeedback={formik.errors.gt}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="idf1" isFloating label={'IDF1'}>
											<Input
												type="number"
												autoComplete="idf1"
												value={formik.values.idf1}
												isTouched={formik.touched.idf1}
												invalidFeedback={formik.errors.idf1}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="idp" isFloating label={'IDP'}>
											<Input
												type="number"
												autoComplete="idp"
												value={formik.values.idp}
												isTouched={formik.touched.idp}
												invalidFeedback={formik.errors.idp}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="idr" isFloating label={'IDR'}>
											<Input
												type="number"
												autoComplete="idr"
												value={formik.values.idr}
												isTouched={formik.touched.idr}
												invalidFeedback={formik.errors.idr}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="ids" isFloating label={'IDS'}>
											<Input
												type="number"
												autoComplete="ids"
												value={formik.values.ids}
												isTouched={formik.touched.ids}
												invalidFeedback={formik.errors.ids}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="ml" isFloating label={'ML'}>
											<Input
												type="number"
												autoComplete="ml"
												value={formik.values.ml}
												isTouched={formik.touched.ml}
												invalidFeedback={formik.errors.ml}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="mota" isFloating label={'MOTA'}>
											<Input
												type="number"
												autoComplete="mota"
												value={formik.values.mota}
												isTouched={formik.touched.mota}
												invalidFeedback={formik.errors.mota}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="motal" isFloating label={'MOTAL'}>
											<Input
												type="number"
												autoComplete="motal"
												value={formik.values.motal}
												isTouched={formik.touched.motal}
												invalidFeedback={formik.errors.motal}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>

									<div className="col-2">
										<FormGroup id="motp" isFloating label={'MOTP'}>
											<Input
												type="number"
												autoComplete="motp"
												value={formik.values.motp}
												isTouched={formik.touched.motp}
												invalidFeedback={formik.errors.motp}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>

									<div className="col-2">
										<FormGroup id="mt" isFloating label={'MT'}>
											<Input
												type="number"
												autoComplete="mt"
												value={formik.values.mt}
												isTouched={formik.touched.mt}
												invalidFeedback={formik.errors.mt}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>

									<div className="col-2">
										<FormGroup id="prcn" isFloating label={'PRCN'}>
											<Input
												type="number"
												autoComplete="prcn"
												value={formik.values.prcn}
												isTouched={formik.touched.prcn}
												invalidFeedback={formik.errors.prcn}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>

									<div className="col-2">
										<FormGroup id="pt" isFloating label={'PT'}>
											<Input
												type="number"
												autoComplete="pt"
												value={formik.values.pt}
												isTouched={formik.touched.pt}
												invalidFeedback={formik.errors.pt}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
									<div className="col-2">
										<FormGroup id="rcll" isFloating label={'RCLL'}>
											<Input
												type="number"
												autoComplete="rcll"
												value={formik.values.rcll}
												isTouched={formik.touched.rcll}
												invalidFeedback={formik.errors.rcll}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
								</form>
							</ModalBody>
							<ModalFooter>
								<div className="col-12">
									<Button
										color="dark"
										type="submit"
										onClick={formik.handleSubmit}
										className="w-100 py-3"
									>
										儲存
									</Button>
								</div>
							</ModalFooter>
						</Modal>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ManageBoard;
