import React, { lazy, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import useSWR from 'swr';
import { getNarlabRouter } from '../../api/narlab';
import PageCardBody from '../../pages/fishEyePages/PageCardBody';
const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));

const ContentRoutes = () => {
  const [paths, setPaths] = useState<any[]>([]);

  const { data: path = { router: [] } } = useSWR(
    '/path',
    () => getNarlabRouter(),
    {
      onSuccess: (data) => {
        if (data.router) {
          const pa = data.router.reduce((map, current) => {
            return [
              ...map,
              {
                path: current.path,
                element: (
                  <PageCardBody>
                    <div
                      dangerouslySetInnerHTML={{ __html: `${current.element}` }}
                    ></div>
                  </PageCardBody>
                ),
                exact: current.exact,
              },
            ];
          }, [] as any[]);
          setPaths(pa.concat(contents));
        }
      },
    }
  );
  return (
    <Routes>
      {paths.map((page) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Route key={page.path} {...page} />
      ))}
      <Route path="*" element={<PAGE_404 />} />
    </Routes>
  );
};

export default ContentRoutes;
