import React, { useState } from 'react';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import { getFirstLetter } from '../../helpers/helpers';
import { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import Button from '../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../components/bootstrap/Modal';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Icon from '../../components/icon/Icon';
import Input from '../../components/bootstrap/forms/Input';
import useSortableData from '../../hooks/useSortableData';

import useDarkMode from '../../hooks/useDarkMode';
import { ModelsGetUserListRes } from '../../swagger/narlab';
import { useDispatch } from 'react-redux';
import * as API from '../../api/narlab';
import useSWR, { mutate } from 'swr';
import showNotification from '../../components/extras/showNotification';

const ManageUsers = () => {
	const dispatch = useDispatch();
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['25']);
	const [loaded, setLoaded] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState<string | null>(null);

	// const { data: user } = getNarlabUser({
	// 	...formik.values,
	// });

	const { data: userData = { user: [] }, error = '' } =
		useSWR<ModelsGetUserListRes>('/user', () => API.getNarlabUser());

	const formik = useFormik({
		initialValues: {
			searchInput: '',
			userName: '',
			userLevel: '',
			userEmail: '',
			userOrganization: '',
			userOldPassword: '',
			userNewPassword: '',
		},
		validate: (values) => {
			const errors: {
				userName?: string;
				userLevel?: string;
				userEmail?: string;
				userOrganization?: string;
				userOldPassword?: string;
				userNewPassword?: string;
			} = {
				userName: undefined,
				userLevel: undefined,
				userEmail: undefined,
				userOrganization: undefined,
				userOldPassword: undefined,
				userNewPassword: undefined,
			};

			if (!values.userName) {
				errors.userName = '請輸入使用者名稱';
			}

			if (!values.userLevel) {
				errors.userLevel = '請輸入職位';
			}

			if (!values.userEmail) {
				errors.userEmail = '請輸入信箱';
			}

			// if (!values.userOldPassword) {
			// 	errors.userOldPassword = '請輸入舊密碼';
			// }

			// if (!values.userNewPassword) {
			// 	errors.userNewPassword = '請輸入新密碼';
			// }

			if (/[\u4e00-\u9fa5]/gi.test(values.userOldPassword)) {
				errors.userOldPassword = '請勿輸入中文';
			}

			if (/[\u4e00-\u9fa5]/gi.test(values.userNewPassword)) {
				errors.userNewPassword = '請勿輸入中文';
			}

			if (
				!/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/g.test(
					values.userEmail
				)
			) {
				errors.userEmail = '請輸入正確信箱格式';
			}

			if (!values.userOrganization) {
				errors.userOrganization = '請輸入公司名稱';
			}

			//錯誤判斷
			const userErrors = Object.values(errors).reduce((map, current) => {
				if (current) {
					return true;
				}
				return map;
			}, false);

			if (!userErrors) {
				return {};
			}
			return errors;
		},

		onSubmit: (values) => {
			// console.log(values);
			if (editModalStatus) {
				API.narlabUserUpdate({
					userId: editModalStatus || '',
					userLevel: values.userLevel || '',
					userEmail: values.userEmail || '',
					userName: values.userName || '',
					userOrganization: values.userOrganization || '',
					userOldPassword: values.userOldPassword || '',
					userNewPassword: values.userNewPassword || '',
				})
					.then((res) => {
						// console.log(res);
						if (res === 'success') {
							showNotification(
								<span className="d-flex align-items-center">
									<Icon icon="Info" size="lg" className="me-1" />
									<span>已成功修改 {values.userName} 使用者的個人資料。</span>
								</span>,

								// 'The web page have been successfully updated.',
								'',
								'success'
							);
							mutate('/user');
							setEditModalStatus(null);
						}
					})
					.catch((err) => {
						// console.log(err.error.messanger);
						showNotification('錯誤', err.error, 'danger');
					});
			} else {
				return;
			}
		},
	});

	function handleEditModal(userId: string) {
		setEditModalStatus(userId);
		API.getNarlabUserDetail(userId)
			.then((res) => {
				formik.setValues({
					searchInput: '',
					userLevel: res.userLevel || '',
					userEmail: res.userEmail || '',
					userName: res.userName || '',
					userOrganization: res.userOrganization || '',
					userOldPassword: '',
					userNewPassword: '',
				});
			})
			.catch((err) => {
				showNotification('錯誤', err.error, 'danger');
			});
		// mutate('/user-detail');
	}

	const filteredData = userData?.user?.filter((f) =>
		// Name
		f?.userName?.toLowerCase().includes(formik.values.searchInput.toLowerCase())
	);

	const { items, requestSort, getClassNamesFor } = useSortableData(
		filteredData || []
	);

	function handleClickDelete(userId: string, userName: string) {
		dispatch({
			type: 'CONFIRM_SHOW',
			payload: {
				title: '刪除聯絡紀錄',
				content: `確定要刪除 ${userName} 使用者?`,
				func: () => {
					API.narlabUserDelete(userId)
						.then((res) => {
							if (res === 'success') {
								showNotification(
									<span className="d-flex align-items-center">
										<Icon icon="Delete" size="lg" className="me-1" />
										<span>使用者已刪除成功。</span>
									</span>,
									''
								);
								mutate('/user');
							}
						})

						.catch((err) => {
							// console.log(err.error);
							showNotification('錯誤', err.error, 'danger');
						});
				},
			},
		});
	}

	return (
		<PageWrapper title="管理使用者">
			<div className="mt-3">
				<SubHeader>
					<SubHeaderLeft>
						<label
							className="border-0 bg-transparent cursor-pointer me-0 "
							htmlFor="searchInput"
						>
							<Icon icon="Search" size="2x" color="dark" />
						</label>
						<Input
							id="searchInput"
							type="search"
							className="border-0 shadow-none bg-transparent"
							placeholder="搜尋使用者..."
							onChange={formik.handleChange}
							value={formik.values.searchInput}
						/>
					</SubHeaderLeft>
				</SubHeader>
			</div>
			<Page>
				<div className="row h-100">
					<div className="col-12">
						<Card stretch>
							<CardBody isScrollable className="table-responsive">
								<table className="table table-modern ">
									<thead>
										<tr>
											<th
												onClick={() => requestSort('userName')}
												className="cursor-pointer text-decoration-underline"
											>
												使用者名稱
												<Icon
													size="lg"
													className={getClassNamesFor('userName')}
													icon="FilterList"
												/>
											</th>
											<th style={{ paddingLeft: 25 }}>信箱</th>
											<th>公司名稱</th>

											<td />
										</tr>
									</thead>
									<tbody>
										{dataPagination(items, currentPage, perPage).map((i) => (
											<tr key={i.userId}>
												<td>
													<div className="d-flex align-items-center">
														<div className="flex-shrink-0">
															<div
																className="ratio ratio-1x1 me-3"
																style={{ width: 48 }}
															>
																<div
																	className={`bg-dark text-light rounded-2 d-flex h2 align-items-center justify-content-center`}
																>
																	<span className="fw-bold">
																		{getFirstLetter(i.userName)}
																	</span>
																</div>
															</div>
														</div>
														<div className="flex-grow-1">
															<div className="fs-6 fw-bold">{i.userName}</div>
															<div className="text-muted">
																<Icon icon="Work" />{' '}
																<small>{i.userLevel}</small>
															</div>
														</div>
													</div>
												</td>
												<td>
													<Button
														isLink
														color="light"
														icon="Email"
														className="text-lowercase"
														tag="a"
														href={`mailto:${i.userEmail}`}
													>
														{i.userEmail}
													</Button>
												</td>
												<td>
													<div className="fs-6 fw-bold">
														{i.userOrganization}
													</div>
												</td>

												<td>
													<Dropdown className="d-inline color-dark">
														<DropdownToggle hasIcon={false}>
															<Button color={themeStatus} icon="MoreHoriz" />
														</DropdownToggle>
														<DropdownMenu isAlignmentEnd>
															<DropdownItem>
																<Button
																	icon="Edit"
																	onClick={() => handleEditModal(i.userId)}
																>
																	編輯
																</Button>
															</DropdownItem>
															<DropdownItem>
																<Button
																	icon="Delete"
																	onClick={() =>
																		handleClickDelete(i.userId, i.userName)
																	}
																>
																	刪除
																</Button>
															</DropdownItem>
														</DropdownMenu>
													</Dropdown>
												</td>
											</tr>
										))}
										<tr>
											<td className="p-0 " colSpan={11}>
												{userData.user?.length ? null : (
													<div
														className="mx-auto d-flex justify-content-center "
														style={{
															height: 200,
															padding: 20,
														}}
													>
														<div className="d-flex flex-column  justify-content-center">
															<div style={{ margin: '0 auto' }}>
																<Icon
																	icon="Inbox"
																	color="dark"
																	className="me-1 h1"
																/>
															</div>
															尚未有資料填入
														</div>
													</div>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
			<Modal
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus ? true : false}
				size="lg"
				data-tour="mail-app-modal"
			>
				<ModalHeader className="px-4" setIsOpen={setEditModalStatus}>
					<CardLabel icon="Person" iconColor="dark">
						<CardTitle>{formik.values.userName}</CardTitle>
					</CardLabel>
				</ModalHeader>

				<ModalBody className="px-4">
					<form onSubmit={formik.handleSubmit} noValidate>
						<CardBody>
							<div className="row g-3 pb-4">
								<div className="col-12 col-xl-6">
									<FormGroup id="userName" label="使用者名稱：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.userName}
											placeholder="使用者名稱"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.userName}
											isTouched={formik.touched?.userName}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>
								<div className="col-12 col-xl-6">
									<FormGroup id="userLevel" label="職位：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.userLevel}
											placeholder="職位"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.userLevel}
											isTouched={formik.touched?.userLevel}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>
								<div className="col-12 col-xl-6">
									<FormGroup id="userEmail" label="信箱：">
										<Input
											type="email"
											onChange={formik.handleChange}
											value={formik.values.userEmail}
											placeholder="信箱"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.userEmail}
											isTouched={formik.touched?.userEmail}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>
								<div className="col-12 col-xl-6">
									<FormGroup id="userOrganization" label="公司名稱：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.userOrganization}
											placeholder="公司名稱"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.userOrganization}
											isTouched={formik.touched?.userOrganization}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>
								<div className="col-12 col-xl-6">
									<FormGroup id="userOldPassword" label="請輸入舊密碼：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.userOldPassword}
											placeholder="舊密碼"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.userOldPassword}
											isTouched={formik.touched?.userOldPassword}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>
								<div className="col-12 col-xl-6">
									<FormGroup id="userNewPassword" label="請輸入新密碼：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.userNewPassword}
											placeholder="新密碼"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.userNewPassword}
											isTouched={formik.touched?.userNewPassword}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>
							</div>
						</CardBody>
					</form>
				</ModalBody>
				<ModalFooter className="px-4 pb-4">
					<Button
						color="dark"
						className="w-100"
						type="submit"
						onClick={formik.handleSubmit}
					>
						儲存
					</Button>
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default ManageUsers;
