/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ModelsConfirmUserReq {
  userId: string;
  userToken: string;
}

export interface ModelsCreateManagerReq {
  /**
   * 執行管理員ID
   * @example "執行管理員ID string"
   */
  execManagerId: string;
  /**
   * 管理員信箱
   * @example "管理員Email string"
   */
  managerEmail: string;
  /**
   * 管理員名稱
   * @example "管理員名稱 string"
   */
  managerName: string;
  /**
   * 管理員權限
   * @example "管理員權限 string"
   */
  managerPermission: string;
  /**
   * 管理員Token（不用塞）
   * @example "不用塞"
   */
  managerToken?: string;
}

export interface ModelsCreateNavReq {
  /**
   * 執行管理員ID
   * @example "執行管理員ID string"
   */
  execManagerId: string;
  /** nav名稱 */
  pageNavName: string;
}

export interface ModelsCreatePageReq {
  /**
   * 執行管理員ID
   * @example "執行管理員ID string"
   */
  execManagerId: string;
  /** 頁面按鈕 */
  pageButton: string;
  /** 頁面內文 */
  pageContent: string;
  /** pageId */
  pageId: string;
  /** 頁面名稱 */
  pageName: string;
  /** navId */
  pageNavId?: string;
  /** 頁面自訂路徑 */
  pagePath: string;
  /** 是否顯示 0:否 1:是 */
  pageStatus: string;
}

export interface ModelsCreateUserReq {
  /**
   * 信箱
   * @example "信箱 string"
   */
  userEmail: string;
  /**
   * 職等
   * @example "職等 string"
   */
  userLevel: string;
  /**
   * 名稱
   * @example "名稱 string"
   */
  userName: string;
  /**
   * 公司名稱
   * @example "公司名稱 string"
   */
  userOrganization: string;
  /**
   * 密碼
   * @example "密碼 string"
   */
  userPassword: string;
  /**
   * UserToken
   * @example "不需要塞值"
   */
  userToken?: string;
}

export interface ModelsCreateUserScoreReq {
  execManagerId: string;
  far?: number;
  fm?: number;
  fn?: number;
  fp?: number;
  gt?: number;
  idf1?: number;
  idp?: number;
  idr?: number;
  ids?: number;
  ml?: number;
  mota?: number;
  motal?: number;
  motp?: number;
  mt?: number;
  prcn?: number;
  pt?: number;
  rcll?: number;
  userId: string;
}

export interface ModelsDeleteManagerReq {
  /**
   * 執行管理員ID
   * @example "執行管理員ID string"
   */
  execManagerId: string;
  /**
   * 管理員ID
   * @example "管理員ID string"
   */
  managerId: string;
}

export interface ModelsDeletePageMediaReq {
  /**
   * 執行管理員ID
   * @example "執行管理員ID string"
   */
  execManagerId: string;
  /** 媒體URL */
  mediaURL: string;
}

export interface ModelsDeleteUserScoreReq {
  /** 執行管理員ID */
  execManagerId: string;
  /** 成績ID */
  scoreId: string;
}

export interface ModelsFrontendNav {
  page?: ModelsNavChildren[];
  /** 頁面ID */
  pageNavId?: string;
  /** 頁面名稱 */
  pageNavName?: string;
}

export interface ModelsGetContentRes {
  /** 建立日期 */
  createDate?: string;
  /** 頁面按鈕 */
  pageButton?: string;
  /** 頁面內文 */
  pageContent?: string;
  /** 頁面ID */
  pageId?: string;
  /** 頁面名稱 */
  pageName?: string;
  /** 更新日期 */
  updateDate?: string;
}

export interface ModelsGetFrontendNavRes {
  /** nav */
  nav?: ModelsFrontendNav[];
}

export interface ModelsGetManagerListRes {
  manager?: ModelsManager[];
}

export interface ModelsGetManagerRes {
  /**
   * 管理員信箱
   * @example "管理員Email string"
   */
  managerEmail?: string;
  /**
   * 管理員ID
   * @example "管理員ID string"
   */
  managerId?: string;
  /**
   * 管理員名稱
   * @example "管理員名稱 string"
   */
  managerName?: string;
  /**
   * 管理員權限 1:超級管理員 0:普通管理員
   * @example "管理員權限 string"
   */
  managerPermission?: string;
}

export interface ModelsGetNavPageRes {
  nav?: Record<string, ModelsNavPage[]>;
}

export interface ModelsGetNavRes {
  /** nav */
  nav?: Record<string, ModelsNav>;
}

export interface ModelsGetPageListRes {
  /** 頁面列表 */
  page?: ModelsPage[];
}

export interface ModelsGetPageRes {
  /** 頁面按鈕 */
  pageButton?: string;
  /** 頁面內文 */
  pageContent?: string;
  /** Exact */
  pageExact?: boolean;
  /** 頁面ID */
  pageId?: string;
  /** 圖片list */
  pageMediaList?: string[];
  /** 頁面名稱 */
  pageName?: string;
  /** nav子順序 */
  pageNavSortChildren?: number;
  /** 頁面自訂路徑 */
  pagePath?: string;
  /** 頁面狀態 */
  pageStatus?: string;
}

export interface ModelsGetPageRouterRes {
  router?: {
    /** 頁面內文 */
    element?: string;
    /** Exact */
    exact?: boolean;
    /** 頁面自訂路徑 */
    path?: string;
  }[];
}

export interface ModelsGetUploadLimitRes {
  uploadLimit?: number;
}

export interface ModelsGetUserListRes {
  user?: {
    /**
     * 信箱
     * @example "信箱 string"
     */
    userEmail?: string;
    /**
     * 用戶ID
     * @example "用戶ID string"
     */
    userId?: string;
    /**
     * 職等
     * @example "職等 string"
     */
    userLevel?: string;
    /**
     * 名稱
     * @example "名稱 string"
     */
    userName?: string;
    /**
     * 公司名稱
     * @example "公司名稱 string"
     */
    userOrganization?: string;
  }[];
}

export interface ModelsGetUserRes {
  /**
   * 信箱
   * @example "信箱 string"
   */
  userEmail?: string;
  /**
   * 用戶ID
   * @example "用戶ID"
   */
  userId?: string;
  /**
   * 職等
   * @example "職等 string"
   */
  userLevel?: string;
  /**
   * 名稱
   * @example "名稱 string"
   */
  userName?: string;
  /**
   * 公司名稱
   * @example "公司名稱 string"
   */
  userOrganization?: string;
  /** 成績列表 */
  userScoreList?: ModelsUserScoreList[];
}

export interface ModelsGetUserScoreHistoryRes {
  /** 成績ID */
  userScoreList?: {
    /**
     * 上傳檔案日期
     * @example "上傳檔案日期 string"
     */
    createDate?: string;
    far?: number;
    /** 檔名 */
    filename?: string;
    fm?: number;
    fn?: number;
    fp?: number;
    gt?: number;
    idf1?: number;
    idp?: number;
    idr?: number;
    ids?: number;
    ml?: number;
    mota?: number;
    motal?: number;
    motp?: number;
    mt?: number;
    prcn?: number;
    pt?: number;
    rcll?: number;
    /**
     * 回傳檔案日期
     * @example "回傳檔案日期 string"
     */
    returnDate?: string;
    /** @example "成績ID string" */
    scoreId?: string;
  }[];
}

export interface ModelsGetUserScoreListReq {
  /** 執行管理員ID */
  execManagerId: string;
}

export interface ModelsGetUserScoreListRes {
  /** 成績列表 */
  userScoreList?: ModelsUserScoreList[];
}

export interface ModelsGetUserScoreLogRes {
  /** 成績記錄 */
  userScoreLogList?: ModelsUserScoreLog[];
}

export interface ModelsGetUserScoreRes {
  /**
   * 上傳檔案日期
   * @example "上傳檔案日期 string"
   */
  createDate?: string;
  /**
   * 建立者
   * @example "建立者 string"
   */
  createUser?: string;
  far?: number;
  fm?: number;
  fn?: number;
  fp?: number;
  gt?: number;
  idf1?: number;
  idp?: number;
  idr?: number;
  ids?: number;
  ml?: number;
  mota?: number;
  motal?: number;
  motp?: number;
  mt?: number;
  prcn?: number;
  pt?: number;
  rcll?: number;
  /**
   * 回傳檔案日期
   * @example "回傳檔案日期 string"
   */
  returnDate?: string;
  /** @example "成績ID string" */
  scoreId?: string;
  /**
   * 更新日期
   * @example "更新日期 string"
   */
  updateDate?: string;
  /**
   * 更新者
   * @example "更新者 string"
   */
  updateUser?: string;
}

export interface ModelsManager {
  /**
   * 管理員信箱
   * @example "管理員信箱 string"
   */
  managerEmail?: string;
  /**
   * 管理員ID
   * @example "管理員ID string"
   */
  managerId?: string;
  /**
   * 管理員名稱
   * @example "管理員名稱 string"
   */
  managerName?: string;
  /**
   * 管理員權限 1:超級管理員 0:普通管理員
   * @example "管理員權限 string"
   */
  managerPermission?: string;
  /**
   * 更新日期
   * @example "2002-11-02 20:00:00"
   */
  updateDate?: string;
}

export interface ModelsManagerAutoLoginReq {
  /**
   * 管理員ID
   * @example "管理員ID string"
   */
  managerId: string;
  /**
   * 管理員權限
   * @example "管理員Token string"
   */
  managerToken: string;
}

export interface ModelsManagerAutoLoginRes {
  /**
   * 管理員信箱
   * @example "管理員Email string"
   */
  managerEmail?: string;
  /**
   * 管理員ID
   * @example "管理員ID string"
   */
  managerId?: string;
  /**
   * 管理員名稱
   * @example "管理員名稱 string"
   */
  managerName?: string;
  /**
   * 管理員權限 1:超級管理員 0:普通管理員
   * @example "管理員權限 string"
   */
  managerPermission?: string;
  /**
   * 管理員權限
   * @example "管理員Token string"
   */
  managerToken?: string;
}

export interface ModelsManagerLoginReq {
  /**
   * 管理員信箱
   * @example "管理員Email string"
   */
  managerEmail: string;
  /**
   * 管理員密碼
   * @example "管理員密碼 string"
   */
  managerPassword: string;
}

export interface ModelsManagerLoginRes {
  /**
   * 管理員信箱
   * @example "管理員Email string"
   */
  managerEmail?: string;
  /**
   * 管理員ID
   * @example "管理員ID string"
   */
  managerId?: string;
  /**
   * 管理員名稱
   * @example "管理員名稱 string"
   */
  managerName?: string;
  /**
   * 管理員權限 1:超級管理員 0:普通管理員
   * @example "管理員權限 string"
   */
  managerPermission?: string;
  /**
   * 管理員權限
   * @example "管理員Token string"
   */
  managerToken?: string;
}

export interface ModelsManagerSetPasswordReq {
  managerId: string;
  managerPassword: string;
  managerToken: string;
}

export interface ModelsNav {
  /** 頁面ID */
  pageNavId?: string;
  /** 頁面名稱 */
  pageNavName?: string;
}

export interface ModelsNavChildren {
  /** 頁面按鈕 */
  pageButton?: string;
  /** Exact */
  pageExact?: boolean;
  /** 頁面ID */
  pageId?: string;
  /** 頁面名稱 */
  pageName?: string;
  /** nav子順序 */
  pageNavSortChildren?: number;
  /** 頁面路徑 */
  pagePath?: string;
  /** 頁面狀態 0:關閉 1:開啟 */
  pageStatus?: string;
}

export interface ModelsNavPage {
  pageButton?: string;
  pageId?: string;
  pageName?: string;
  pagePath?: string;
}

export interface ModelsPage {
  /** 頁面按鈕 */
  pageButton?: string;
  /** 頁面ID */
  pageId?: string;
  /** 頁面名稱 */
  pageName?: string;
  /** Nav名稱 */
  pageNavName?: string;
  /** nav子順序 從1開始 */
  pageNavSortChildren?: number;
  /** 頁面路徑 */
  pagePath?: string;
  /** 頁面狀態 */
  pageStatus?: string;
  updateDate?: string;
}

export interface ModelsSendConfirmUserEmailReq {
  userId: string;
}

export interface ModelsSendManagerSetPasswordReq {
  managerId: string;
}

export interface ModelsSendUserForgetPasswordEmailReq {
  userEmail: string;
}

export interface ModelsUpdateManagerReq {
  /**
   * 執行管理員ID
   * @example "執行管理員ID string"
   */
  execManagerId: string;
  /**
   * 管理員信箱
   * @example "管理員Email string"
   */
  managerEmail: string;
  /**
   * 管理員ID
   * @example "管理員ID string"
   */
  managerId: string;
  /**
   * 管理員名稱
   * @example "管理員名稱 string"
   */
  managerName: string;
  /**
   * 管理員新密碼
   * @example "新密碼 string"
   */
  managerNewPassword?: string;
  /**
   * 管理員舊密碼
   * @example "舊密碼 string"
   */
  managerOldPassword?: string;
  /**
   * 管理員權限
   * @example "管理員權限 string"
   */
  managerPermission: string;
}

export interface ModelsUpdateNav {
  page: ModelsUpdateNavPage[];
  pageNavId: string;
  pageNavName: string;
}

export interface ModelsUpdateNavNameReq {
  pageNavId: string;
  pageNavName: string;
}

export interface ModelsUpdateNavPage {
  /** 頁面按鈕 */
  pageButton: string;
  /** 頁面ID */
  pageId: string;
  /** 頁面名稱 */
  pageName: string;
  /** 頁面路徑 */
  pagePath: string;
}

export interface ModelsUpdateNavReq {
  /** @example "執行管理員ID string" */
  execManagerId: string;
  nav: Record<string, ModelsUpdateNav>;
}

export interface ModelsUpdatePageNavReq {
  /** 執行管理員ID */
  execManagerId: string;
  /** 頁面內文 */
  pageId: string;
  /** NavId */
  pageNavId: string;
  /** 頁面子順序 */
  pageNavSortChildren?: number;
}

export interface ModelsUpdatePageReq {
  /**
   * 執行管理員ID
   * @example "執行管理員ID string"
   */
  execManagerId: string;
  /** 頁面按鈕 */
  pageButton: string;
  /** 頁面內文 */
  pageContent: string;
  /** 頁面ID */
  pageId: string;
  pageMediaList?: string[];
  /** 頁面名稱 */
  pageName: string;
  /** navId */
  pageNavId?: string;
  /** 頁面自訂路徑 */
  pagePath?: string;
  /** 是否顯示 0:否 1:是 */
  pageStatus: string;
}

export interface ModelsUpdateUploadLimitReq {
  uploadLimit: number;
}

export interface ModelsUpdateUserReq {
  /**
   * 信箱
   * @example "信箱 string"
   */
  userEmail: string;
  /**
   * 用戶ID
   * @example "用戶ID string"
   */
  userId: string;
  /**
   * 職等
   * @example "職等 string"
   */
  userLevel: string;
  /**
   * 名稱
   * @example "名稱 string"
   */
  userName: string;
  /**
   * 新密碼
   * @example "新密碼 string"
   */
  userNewPassword?: string;
  /**
   * 舊密碼
   * @example "舊密碼 string"
   */
  userOldPassword?: string;
  /**
   * 公司名稱
   * @example "公司名稱 string"
   */
  userOrganization: string;
}

export interface ModelsUpdateUserScoreReq {
  execManagerId: string;
  far?: number;
  fm?: number;
  fn?: number;
  fp?: number;
  gt?: number;
  idf1?: number;
  idp?: number;
  idr?: number;
  ids?: number;
  ml?: number;
  mota?: number;
  motal?: number;
  motp?: number;
  mt?: number;
  prcn?: number;
  pt?: number;
  rcll?: number;
  scoreId: string;
}

export interface ModelsUserAutoLoginReq {
  /**
   * 用戶ID
   * @example "用戶ID"
   */
  userId: string;
  /**
   * 用戶Token
   * @example "用戶Token string"
   */
  userToken: string;
}

export interface ModelsUserAutoLoginRes {
  /**
   * 信箱
   * @example "信箱 string"
   */
  userEmail?: string;
  /**
   * 用戶ID
   * @example "用戶ID"
   */
  userId?: string;
  /**
   * 職等
   * @example "職等 string"
   */
  userLevel?: string;
  /**
   * 名稱
   * @example "名稱 string"
   */
  userName?: string;
  /**
   * 公司名稱
   * @example "公司名稱 string"
   */
  userOrganization?: string;
  /** 成績列表 */
  userScoreList?: ModelsUserScoreList[];
  /**
   * 用戶狀態 0：未驗證 1:已驗證
   * @example "用戶狀態 string"
   */
  userStatus?: string;
  /**
   * 用戶Token
   * @example "用戶Token string"
   */
  userToken?: string;
}

export interface ModelsUserForgetPasswordReq {
  newPassword: string;
  userId: string;
  userToken: string;
}

export interface ModelsUserLoginReq {
  /**
   * 信箱
   * @example "信箱 string"
   */
  userEmail: string;
  /**
   * 密碼
   * @example "密碼 string"
   */
  userPassword: string;
}

export interface ModelsUserLoginRes {
  /**
   * 信箱
   * @example "信箱 string"
   */
  userEmail?: string;
  /**
   * 用戶ID
   * @example "用戶ID"
   */
  userId?: string;
  /**
   * 職等
   * @example "職等 string"
   */
  userLevel?: string;
  /**
   * 名稱
   * @example "名稱 string"
   */
  userName?: string;
  /**
   * 公司名稱
   * @example "公司名稱 string"
   */
  userOrganization?: string;
  /** 成績列表 */
  userScoreList?: ModelsUserScoreList[];
  /**
   * 用戶狀態 0：未驗證 1:已驗證
   * @example "用戶狀態 string"
   */
  userStatus?: string;
  /**
   * 用戶Token
   * @example "用戶Token string"
   */
  userToken?: string;
}

export interface ModelsUserScoreList {
  /**
   * 上傳檔案日期
   * @example "上傳檔案日期 string"
   */
  createDate?: string;
  far?: number;
  fm?: number;
  fn?: number;
  fp?: number;
  gt?: number;
  idf1?: number;
  idp?: number;
  idr?: number;
  ids?: number;
  ml?: number;
  mota?: number;
  motal?: number;
  motp?: number;
  mt?: number;
  prcn?: number;
  pt?: number;
  /**
   * 排名
   * @example 1
   */
  rank?: number;
  rcll?: number;
  /**
   * 回傳檔案日期
   * @example "回傳檔案日期 string"
   */
  returnDate?: string;
  /**
   * 成績ID
   * @example "成績ID string"
   */
  scoreId?: string;
  userName?: string;
}

export interface ModelsUserScoreLog {
  /** 建立日期 */
  createDate?: string;
  /** 記錄文字 */
  logAction?: string;
  /** 記錄id */
  logId?: string;
}

export interface MediaCreatePayload {
  /**
   * 圖片
   * @format binary
   */
  media: File;
  /** pageId */
  pageId: string;
  /** execManagerId */
  execManagerId: string;
  /** 副檔名 */
  extension: string;
}

export interface FileCreatePayload {
  /**
   * file1
   * @format binary
   */
  file1: File;
  /**
   * file2
   * @format binary
   */
  file2: File;
  /**
   * file3
   * @format binary
   */
  file3: File;
  /** userId */
  userId: string;
  /** file1Extension */
  file1Extension: string;
  /** file2Extension */
  file2Extension: string;
  /** file3Extension */
  file3Extension: string;
  /** fileName1 */
  fileName1: string;
  /** fileName2 */
  fileName2: string;
  /** fileName3 */
  fileName3: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://localhost:80";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title NarLabs
 * @version 1.0
 * @baseUrl http://localhost:80
 * @contact sherryMiet
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  email = {
    /**
     * No description
     *
     * @tags 信件相關
     * @name ConfirmCreate
     * @summary 傳送驗證信
     * @request POST:/email/confirm
     */
    confirmCreate: (JSON: ModelsSendConfirmUserEmailReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/email/confirm`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 信件相關
     * @name ManagerCreate
     * @summary 寄送管理員設定密碼信
     * @request POST:/email/manager
     */
    managerCreate: (JSON: ModelsSendManagerSetPasswordReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/email/manager`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 信件相關
     * @name PasswordCreate
     * @summary 寄送忘記密碼信
     * @request POST:/email/password
     */
    passwordCreate: (JSON: ModelsSendUserForgetPasswordEmailReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/email/password`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  frontend = {
    /**
     * No description
     *
     * @tags 頁面相關
     * @name GetFrontend
     * @summary 取得前台nav
     * @request GET:/frontend/nav
     */
    getFrontend: (params: RequestParams = {}) =>
      this.request<ModelsGetFrontendNavRes, string>({
        path: `/frontend/nav`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 成績相關
     * @name ScoreList
     * @summary 前台取得所有用戶排名
     * @request GET:/frontend/score
     */
    scoreList: (params: RequestParams = {}) =>
      this.request<ModelsGetUserScoreListRes, string>({
        path: `/frontend/score`,
        method: "GET",
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 成績相關
     * @name ScoreLogDetail
     * @summary 前台取得檔案上傳記錄
     * @request GET:/frontend/score_log/{scoreId}
     */
    scoreLogDetail: (scoreId: string, params: RequestParams = {}) =>
      this.request<ModelsGetUserScoreLogRes, string>({
        path: `/frontend/score_log/${scoreId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name FrontendDetail
     * @summary 前台取得文章內文
     * @request GET:/frontend/{pageId}
     */
    frontendDetail: (pageId: string, params: RequestParams = {}) =>
      this.request<ModelsGetContentRes, string>({
        path: `/frontend/${pageId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  manager = {
    /**
     * No description
     *
     * @tags 後台相關
     * @name ManagerUpdate
     * @summary 更新管理員
     * @request PUT:/manager
     */
    managerUpdate: (JSON: ModelsUpdateManagerReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/manager`,
        method: "PUT",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name ManagerCreate
     * @summary 建立管理員
     * @request POST:/manager
     */
    managerCreate: (JSON: ModelsCreateManagerReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/manager`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name ManagerDelete
     * @summary 刪除管理員
     * @request DELETE:/manager
     */
    managerDelete: (JSON: ModelsDeleteManagerReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/manager`,
        method: "DELETE",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name AutoLoginCreate
     * @summary 管理員自動登入
     * @request POST:/manager/auto_login
     */
    autoLoginCreate: (JSON: ModelsManagerAutoLoginReq, params: RequestParams = {}) =>
      this.request<ModelsManagerAutoLoginRes, string>({
        path: `/manager/auto_login`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name LimitList
     * @summary 後台編輯上傳次數
     * @request GET:/manager/limit
     */
    limitList: (params: RequestParams = {}) =>
      this.request<ModelsGetUploadLimitRes, string>({
        path: `/manager/limit`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name LimitUpdate
     * @summary 後台編輯上傳次數
     * @request PUT:/manager/limit
     */
    limitUpdate: (JSON: ModelsUpdateUploadLimitReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/manager/limit`,
        method: "PUT",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name ListDetail
     * @summary 取得管理員列表
     * @request GET:/manager/list/{managerId}
     */
    listDetail: (managerId: string, params: RequestParams = {}) =>
      this.request<ModelsGetManagerListRes, string>({
        path: `/manager/list/${managerId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name LoginCreate
     * @summary 管理員登入
     * @request POST:/manager/login
     */
    loginCreate: (JSON: ModelsManagerLoginReq, params: RequestParams = {}) =>
      this.request<ModelsManagerLoginRes, string>({
        path: `/manager/login`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name LogoutDelete
     * @summary 管理員登出
     * @request DELETE:/manager/logout/{managerId}
     */
    logoutDelete: (managerId: string, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/manager/logout/${managerId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 信件相關
     * @name PasswordCreate
     * @summary 管理員設定密碼
     * @request POST:/manager/password
     */
    passwordCreate: (JSON: ModelsManagerSetPasswordReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/manager/password`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 後台相關
     * @name ManagerDetail
     * @summary 取得管理員資訊
     * @request GET:/manager/{managerId}
     */
    managerDetail: (managerId: string, params: RequestParams = {}) =>
      this.request<ModelsGetManagerRes, string>({
        path: `/manager/${managerId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  nav = {
    /**
     * No description
     *
     * @tags 頁面相關
     * @name GetNav
     * @summary 取得文章nav列表
     * @request GET:/nav
     */
    getNav: (params: RequestParams = {}) =>
      this.request<ModelsGetNavRes, string>({
        path: `/nav`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name PutNav
     * @summary 更新Nav
     * @request PUT:/nav
     */
    putNav: (JSON: ModelsUpdateNavReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/nav`,
        method: "PUT",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name PostNav
     * @summary 新建Nav
     * @request POST:/nav
     */
    postNav: (JSON: ModelsCreateNavReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/nav`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name NameUpdate
     * @summary 更新Nav名稱
     * @request PUT:/nav/name
     */
    nameUpdate: (JSON: ModelsUpdateNavNameReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/nav/name`,
        method: "PUT",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name PageList
     * @summary 取得文章nav
     * @request GET:/nav/page
     */
    pageList: (params: RequestParams = {}) =>
      this.request<ModelsGetNavPageRes, string>({
        path: `/nav/page`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name DeleteNav
     * @summary 刪除Nav
     * @request DELETE:/nav/{navId}/{execManagerId}
     */
    deleteNav: (navId: string, execManagerId: string, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/nav/${navId}/${execManagerId}`,
        method: "DELETE",
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  page = {
    /**
     * No description
     *
     * @tags 頁面相關
     * @name PageUpdate
     * @summary 更新頁面
     * @request PUT:/page
     */
    pageUpdate: (JSON: ModelsUpdatePageReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/page`,
        method: "PUT",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name PageCreate
     * @summary 建立頁面
     * @request POST:/page
     */
    pageCreate: (JSON: ModelsCreatePageReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/page`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name ListDetail
     * @summary 頁面列表
     * @request GET:/page/list/{execManagerId}
     */
    listDetail: (execManagerId: string, params: RequestParams = {}) =>
      this.request<ModelsGetPageListRes, string>({
        path: `/page/list/${execManagerId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name MediaCreate
     * @summary 頁面新增媒體檔案
     * @request POST:/page/media
     */
    mediaCreate: (data: MediaCreatePayload, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/page/media`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name MediaDelete
     * @summary 頁面刪除媒體檔案
     * @request DELETE:/page/media
     */
    mediaDelete: (JSON: ModelsDeletePageMediaReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/page/media`,
        method: "DELETE",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name PutPage
     * @summary 更新頁面Nav
     * @request PUT:/page/nav
     */
    putPage: (JSON: ModelsUpdatePageNavReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/page/nav`,
        method: "PUT",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name RouterList
     * @summary 取得頁面路徑
     * @request GET:/page/router
     */
    routerList: (params: RequestParams = {}) =>
      this.request<ModelsGetPageRouterRes, string>({
        path: `/page/router`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name PageDetail
     * @summary 取得頁面
     * @request GET:/page/{pageId}/{execManagerId}
     */
    pageDetail: (pageId: string, execManagerId: string, params: RequestParams = {}) =>
      this.request<ModelsGetPageRes, string>({
        path: `/page/${pageId}/${execManagerId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 頁面相關
     * @name PageDelete
     * @summary 刪除頁面
     * @request DELETE:/page/{pageId}/{execManagerId}
     */
    pageDelete: (pageId: string, execManagerId: string, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/page/${pageId}/${execManagerId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  score = {
    /**
     * No description
     *
     * @tags 成績相關
     * @name ScoreUpdate
     * @summary 更新用戶分數
     * @request PUT:/score
     */
    scoreUpdate: (JSON: ModelsUpdateUserScoreReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/score`,
        method: "PUT",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 成績相關
     * @name ScoreCreate
     * @summary 建立用戶分數
     * @request POST:/score
     */
    scoreCreate: (JSON: ModelsCreateUserScoreReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/score`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 成績相關
     * @name ScoreDelete
     * @summary 刪除用戶分數
     * @request DELETE:/score
     */
    scoreDelete: (JSON: ModelsDeleteUserScoreReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/score`,
        method: "DELETE",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 成績相關
     * @name HistoryDetail
     * @summary 取得用戶分數歷程
     * @request GET:/score/history/{userId}/{execManagerId}
     */
    historyDetail: (userId: string, execManagerId: string, params: RequestParams = {}) =>
      this.request<ModelsGetUserScoreHistoryRes, string>({
        path: `/score/history/${userId}/${execManagerId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 成績相關
     * @name ListCreate
     * @summary 取得所有用戶排名
     * @request POST:/score/list
     */
    listCreate: (JSON: ModelsGetUserScoreListReq, params: RequestParams = {}) =>
      this.request<ModelsGetUserScoreListRes, string>({
        path: `/score/list`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 成績相關
     * @name ScoreDetail
     * @summary 取得用戶分數
     * @request GET:/score/{scoreId}/{execManagerId}
     */
    scoreDetail: (scoreId: string, execManagerId: string, params: RequestParams = {}) =>
      this.request<ModelsGetUserScoreRes, string>({
        path: `/score/${scoreId}/${execManagerId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  scoreLog = {
    /**
     * No description
     *
     * @tags 成績相關
     * @name ScoreLogDetail
     * @summary 取得檔案上傳記錄
     * @request GET:/score_log/{scoreId}/{execManagerId}
     */
    scoreLogDetail: (scoreId: string, execManagerId: string, params: RequestParams = {}) =>
      this.request<ModelsGetUserScoreLogRes, string>({
        path: `/score_log/${scoreId}/${execManagerId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags 用戶相關
     * @name UserUpdate
     * @summary 更新用戶
     * @request PUT:/user
     */
    userUpdate: (JSON: ModelsUpdateUserReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/user`,
        method: "PUT",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 用戶相關
     * @name UserCreate
     * @summary 建立用戶
     * @request POST:/user
     */
    userCreate: (JSON: ModelsCreateUserReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/user`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 用戶相關
     * @name AutoLoginCreate
     * @summary 用戶自動登入
     * @request POST:/user/auto_login
     */
    autoLoginCreate: (JSON: ModelsUserAutoLoginReq, params: RequestParams = {}) =>
      this.request<ModelsUserAutoLoginRes, string>({
        path: `/user/auto_login`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 信件相關
     * @name ConfirmCreate
     * @summary 驗證用戶
     * @request POST:/user/confirm
     */
    confirmCreate: (JSON: ModelsConfirmUserReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/user/confirm`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 成績相關
     * @name FileCreate
     * @summary 檔案上傳
     * @request POST:/user/file
     */
    fileCreate: (data: FileCreatePayload, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/user/file`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 用戶相關
     * @name ListList
     * @summary 取得用戶列表
     * @request GET:/user/list
     */
    listList: (params: RequestParams = {}) =>
      this.request<ModelsGetUserListRes, string>({
        path: `/user/list`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 用戶相關
     * @name LoginCreate
     * @summary 用戶登入
     * @request POST:/user/login
     */
    loginCreate: (JSON: ModelsUserLoginReq, params: RequestParams = {}) =>
      this.request<ModelsUserLoginRes, string>({
        path: `/user/login`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 用戶相關
     * @name LogoutDelete
     * @summary 用戶登出
     * @request DELETE:/user/logout/{userId}
     */
    logoutDelete: (userId: string, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/user/logout/${userId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 信件相關
     * @name PasswordCreate
     * @summary 忘記密碼修改回傳
     * @request POST:/user/password
     */
    passwordCreate: (JSON: ModelsUserForgetPasswordReq, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/user/password`,
        method: "POST",
        body: JSON,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 用戶相關
     * @name UserDetail
     * @summary 取得用戶
     * @request GET:/user/{userId}
     */
    userDetail: (userId: string, params: RequestParams = {}) =>
      this.request<ModelsGetUserRes, string>({
        path: `/user/${userId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags 用戶相關
     * @name UserDelete
     * @summary 刪除用戶
     * @request DELETE:/user/{userId}
     */
    userDelete: (userId: string, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/user/${userId}`,
        method: "DELETE",
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
