import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Card, { CardBody } from '../../components/bootstrap/Card';
import './style.css';

interface PageCardBodyProps extends Record<string, any> {
	children: ReactNode;
}

const PageCardBody: React.FC<PageCardBodyProps> = ({ children }) => {
	return (
		<div className="container">
			<div className="row">
				<div className="col-12 mt-3">
					<Card>
						<CardBody className="pageCardImg">{children}</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
};

PageCardBody.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PageCardBody;
