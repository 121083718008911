import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import { getFirstLetter } from '../../helpers/helpers';
import { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import Button from '../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Icon from '../../components/icon/Icon';
import Input from '../../components/bootstrap/forms/Input';
import useSortableData from '../../hooks/useSortableData';
import Option from '../../components/bootstrap/Option';
import * as API from '../../api/narlab';
import useSWR, { mutate } from 'swr';
import showNotification from '../../components/extras/showNotification';
import Select from '../../components/bootstrap/forms/Select';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../components/bootstrap/Modal';
import useDarkMode from '../../hooks/useDarkMode';
import { useDispatch } from 'react-redux';
import AuthContext from '../../contexts/authContext';

const ManageAdminUsers = () => {
	const dispatch = useDispatch();
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['25']);
	const { user } = useContext(AuthContext);
	const [manageModalStatus, setManageModalStatus] = useState(Boolean || null);
	const [handleManageId, setHandleManageId] = useState('');
	const [manageType, setManageType] = useState('');

	const { data: managerData = { manager: [] }, error = '' } = useSWR(
		user.userId && '/managers',
		() => API.managerList(user.userId || '')
	);

	const formik = useFormik({
		initialValues: {
			searchInput: '',
			managerEmail: '',
			managerName: '',
			managerPermission: '',
			managerPassword: '',
			managerOldPassword: '',
			managerNewPassword: '',
		},
		validate: (values) => {
			const errors: {
				managerName?: string;
				managerEmail?: string;
				managerPassword?: string;
				managerPermission?: string;
			} = {
				managerName: undefined,
				managerEmail: undefined,
				managerPassword: undefined,
				managerPermission: undefined,
			};

			if (!values.managerName) {
				errors.managerName = '請輸入管理者名稱';
			}

			if (!values.managerEmail) {
				errors.managerEmail = '請輸入管理者信箱';
			}

			if (!values.managerPermission) {
				errors.managerPermission = '請選擇館分者權限';
			}

			if (
				!/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/g.test(
					values.managerEmail
				)
			) {
				errors.managerEmail = '請輸入正確信箱格式';
			}

			if (/[\u4e00-\u9fa5]/gi.test(values.managerPassword)) {
				errors.managerPassword = '請勿輸入中文';
			}

			if (!values.managerPassword) {
				errors.managerPassword = '請輸入管理員密碼';
			}

			//錯誤判斷
			const managerErrors = Object.values(errors).reduce((map, current) => {
				if (current) {
					return true;
				}
				return map;
			}, false);

			if (!managerErrors) {
				return {};
			}
			return managerErrors;
		},

		onSubmit: (values) => {
			// console.log(values);
			if (manageType === 'edit') {
				// console.log('-------編輯');
				// console.log(values);
				API.managerUpdate({
					execManagerId: user.userId || '',
					managerEmail: values.managerEmail || '',
					managerId: handleManageId,
					managerName: values.managerName || '',

					managerOldPassword: values.managerOldPassword || '',
					managerNewPassword: values.managerNewPassword || '',
					managerPermission: values.managerPermission || '',
				})
					.then((res) => {
						// console.log(res);
						if (res === 'success') {
							showNotification(
								<span className="d-flex align-items-center">
									<Icon icon="Info" size="lg" className="me-1" />
									<span>已更改成功。</span>
								</span>,
								'',
								'success'
							);

							mutate('/managers');
							setManageModalStatus(false);
						}
					})
					.catch((err) => {
						// console.log(err.error.messanger);
						showNotification('錯誤', err.error, 'danger');
					});
			} else {
				// console.log('-------新增');
				// console.log(values);
				API.managerCreate({
					execManagerId: user.userId || '',
					managerEmail: values.managerEmail || '',
					managerName: values.managerName || '',
					managerPermission: values.managerPermission || '',
				})
					.then((res) => {
						API.managerEmailCreate({ managerId: res })
							.then(() => {
								showNotification(
									<span className="d-flex align-items-center">
										<Icon icon="Info" size="lg" className="me-1" />
										<span>已成功新增 {values.managerName} 的權限。</span>
									</span>,
									'',
									'success'
								);
							})

							.catch((err) => {
								// console.log(err.error.messanger);
								showNotification('錯誤', err.error, 'danger');
							});
						mutate('/managers');
						setManageModalStatus(false);
					})
					.catch((err) => {
						// console.log(err.error.messanger);
						showNotification('錯誤', err.error, 'danger');
					});
			}
		},
	});

	const filteredData = managerData?.manager?.filter((f) =>
		// Name
		f?.managerName
			?.toLowerCase()
			.includes(formik.values.searchInput.toLowerCase())
	);

	const { items, requestSort, getClassNamesFor } = useSortableData(
		filteredData || []
	);

	function handleEditModal(managerId: string) {
		setManageModalStatus(true);
		setManageType('edit');
		setHandleManageId(managerId);

		// console.log(manageModalStatus);
		API.managerDetail(managerId || '')
			.then((res) => {
				formik.setValues({
					searchInput: '',
					managerEmail: res.managerEmail || '',
					managerName: res.managerName || '',
					managerPermission: res.managerPermission || '',
					managerPassword: '',
					managerOldPassword: '',
					managerNewPassword: '',
				});
			})
			.catch((err) => {
				showNotification('錯誤', err.error, 'danger');
			});

		// setManageModalStatus(true);
	}

	function handleClickDelete(managerId: string, managerName: string) {
		dispatch({
			type: 'CONFIRM_SHOW',
			payload: {
				title: '刪除聯絡紀錄',
				content: `確定要刪除 ${managerName} 管理員?`,
				func: () => {
					API.managerDelete({ execManagerId: user.userId || '', managerId })
						.then((res) => {
							// console.log(res);

							if (res === 'success') {
								showNotification(
									<span className="d-flex align-items-center">
										<Icon icon="Delete" size="lg" className="me-1" />
										<span>已刪除成功。</span>
									</span>,
									'success'
								);
								mutate('/managers');
							}
						})

						.catch((err) => {
							// console.log(err.error);
							showNotification('錯誤', err.error, 'danger');
						});
				},
			},
		});
	}

	function handleClickAdd() {
		formik.resetForm();
		setManageModalStatus(true);
		setManageType('create');
	}

	return (
		<PageWrapper title="管理者權限">
			<div className="mt-3">
				<SubHeader>
					<SubHeaderLeft>
						<label
							className="border-0 bg-transparent cursor-pointer me-0 "
							htmlFor="searchInput"
						>
							<Icon icon="Search" size="2x" color="dark" />
						</label>
						<Input
							id="searchInput"
							type="search"
							className="border-0 shadow-none bg-transparent"
							placeholder="搜尋管理者權限..."
							onChange={formik.handleChange}
							value={formik.values.searchInput}
						/>
					</SubHeaderLeft>
					<SubHeaderRight>
						<SubheaderSeparator />
						<Button
							color="dark"
							icon="PersonAddAlt1"
							onClick={() => {
								handleClickAdd();
							}}
						>
							新增管理權限
						</Button>
					</SubHeaderRight>
				</SubHeader>
			</div>
			<Page>
				<div className="row h-100">
					<div className="col-12">
						<Card stretch>
							<CardBody isScrollable className="table-responsive">
								<table className="table table-modern table-hover">
									<thead>
										<tr>
											<th
												onClick={() => requestSort('name')}
												className="cursor-pointer text-decoration-underline"
											>
												使用者名稱
												<Icon
													size="lg"
													className={getClassNamesFor('name')}
													icon="FilterList"
												/>
											</th>
											<th style={{ paddingLeft: 25 }}>信箱</th>
											<th onClick={() => requestSort('updateDate')}>
												編輯日期
												<Icon
													size="lg"
													className={getClassNamesFor('updateDate')}
													icon="FilterList"
												/>
											</th>

											<td />
										</tr>
									</thead>
									<tbody>
										{dataPagination(items, currentPage, perPage).map(
											(i, index) => (
												<tr key={index}>
													<td>
														<div className="d-flex align-items-center">
															<div className="flex-shrink-0">
																<div
																	className="ratio ratio-1x1 me-3"
																	style={{ width: 48 }}
																>
																	<div
																		className={`bg-dark text-light rounded-2 d-flex h2 align-items-center justify-content-center`}
																	>
																		<span className="fw-bold">
																			{getFirstLetter(i.managerName)}
																		</span>
																	</div>
																</div>
															</div>
															<div className="flex-grow-1">
																<div className="fs-6 fw-bold">
																	{i.managerName}
																</div>
																<div className="text-muted">
																	<Icon icon="Person" />
																	<small>
																		{i.managerPermission == '1' ? (
																			<>超級管理員</>
																		) : (
																			<>一般管理員</>
																		)}
																	</small>
																</div>
															</div>
														</div>
													</td>
													<td>
														<Button
															isLink
															color="light"
															icon="Email"
															className="text-lowercase"
															tag="a"
															href={`mailto:${i.managerEmail}`}
														>
															{i.managerEmail}
														</Button>
													</td>
													<td>
														<div className="fs-6 fw-bold">{i.updateDate}</div>
													</td>

													<td>
														<Dropdown className="d-inline color-dark">
															<DropdownToggle hasIcon={false}>
																<Button color={themeStatus} icon="MoreHoriz" />
															</DropdownToggle>
															<DropdownMenu isAlignmentEnd>
																<DropdownItem>
																	<Button
																		icon="Edit"
																		onClick={() => handleEditModal(i.managerId)}
																	>
																		編輯
																	</Button>
																</DropdownItem>
																<DropdownItem>
																	<Button
																		icon="Delete"
																		onClick={() =>
																			handleClickDelete(
																				i.managerId,
																				i.managerName
																			)
																		}
																	>
																		刪除
																	</Button>
																</DropdownItem>
															</DropdownMenu>
														</Dropdown>
													</td>
												</tr>
											)
										)}
										<tr>
											<td className="p-0 " colSpan={11}>
												{managerData.manager?.length ? null : (
													<div
														className="mx-auto d-flex justify-content-center "
														style={{
															height: 200,
															padding: 20,
														}}
													>
														<div className="d-flex flex-column  justify-content-center">
															<div style={{ margin: '0 auto' }}>
																<Icon
																	icon="Inbox"
																	color="dark"
																	className="me-1 h1"
																/>
															</div>
															尚未有資料填入
														</div>
													</div>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
			<Modal
				setIsOpen={setManageModalStatus}
				isOpen={manageModalStatus}
				size="lg"
				data-tour="mail-app-modal"
			>
				<ModalHeader className="px-4" setIsOpen={setManageModalStatus}>
					<CardLabel icon="Person" iconColor="info">
						<CardTitle>{formik.values.managerName}</CardTitle>
					</CardLabel>
				</ModalHeader>

				<ModalBody className="px-4">
					<form onSubmit={formik.handleSubmit} noValidate>
						<CardBody>
							<div className="row g-3 pb-4">
								<div className="col-12 ">
									<FormGroup id="managerName" label="管理者名稱：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.managerName}
											placeholder="管理者名稱"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.managerName}
											isTouched={formik.touched?.managerName}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup id="managerEmail" label="信箱：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.managerEmail}
											placeholder="信箱"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.managerEmail}
											isTouched={formik.touched?.managerEmail}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div>

								<div className="col-12 ">
									<FormGroup id="managerPermission" label="管理員權限：">
										<Select
											id="managerPermission"
											placeholder="請選擇權限"
											ariaLabel="managerPermission"
											value={formik.values.managerPermission}
											onChange={formik.handleChange}
											invalidFeedback={formik.errors.managerPermission}
											isTouched={formik.touched.managerPermission}
											onBlur={formik.handleBlur}
											isValid={formik.isValid}
										>
											<Option key="0" value="0">
												一般管理員
											</Option>
											<Option key="1" value="1">
												超級管理員
											</Option>
										</Select>
									</FormGroup>
								</div>

								{/* <div className="col-12 col-xl-6">
									<FormGroup id="managerPassword" label="管理員密碼：">
										<Input
											onChange={formik.handleChange}
											value={formik.values.managerPassword}
											placeholder="管理員密碼"
											isValid={formik.isValid}
											invalidFeedback={formik.errors?.managerPassword}
											isTouched={formik.touched?.managerPassword}
											onBlur={formik.handleBlur}
										/>
									</FormGroup>
								</div> */}
							</div>
						</CardBody>
					</form>
				</ModalBody>
				<ModalFooter className="px-4 pb-4">
					<Button
						color="dark"
						className="w-100"
						type="submit"
						onClick={formik.handleSubmit}
					>
						{manageType === 'create' ? '新增管理權限' : '儲存管理權限'}
					</Button>
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default ManageAdminUsers;
