import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';
import * as API from '../../../api/narlab';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';
import Spinner from '../../../components/bootstrap/Spinner';

const Verify = () => {
	const { darkModeStatus } = useDarkMode();
	const { userId, userToken } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (userId && userToken) {
			API.confirmUser({
				userId: userId,
				userToken: userToken,
			})
				.then((res) => {
					showNotification('Success', res, 'success');
					navigate('/login');
				})
				.catch((err) => {
					showNotification('Errors', err.error, 'danger');
				});
		}
	}, [userId, userToken]);

	const LoginHeader = () => {
		return (
			<>
				{/* <div className="text-center h1 fw-bold mt-3">驗證使用者,</div> */}
				<div className="text-center h4 text-muted m-2">
					<div>Verifying user, please wait...</div>
					<Spinner className="m-5" isGrow color={'dark'} />
				</div>
			</>
		);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={'Verifying User'}
			className={classNames({
				'bg-light': true,
				'bg-dark': false,
			})}
		>
			<Page className="p-0">
				<div className="row h-100 align-items-center justify-content-center">
					<div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
						<Card
							className={classNames({
								'shadow-3d-dark': false,
								'shadow-3d-light': true,
							})}
							data-tour="login-page"
						>
							<CardBody>
								<div className="text-center my-1">
									<Icon icon="AccountCircle" size="4x" />
									<Link
										to="/"
										className={classNames(
											'text-decoration-none  fw-bold display-6',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											}
										)}
									>
										<br />
										{/* <Logo width={300} /> */}
										Fish-Eye Traffic Data Competition Website
									</Link>
								</div>

								<LoginHeader />
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Verify;
