import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import Button from '../../components/bootstrap/Button';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';

import Icon from '../../components/icon/Icon';
import { dataPagination, PER_COUNT } from '../../components/PaginationButtons';
import useSortableData from '../../hooks/useSortableData';
import useDarkMode from '../../hooks/useDarkMode';
import { useFormik } from 'formik';
import Badge from '../../components/bootstrap/Badge';
import AuthContext from '../../contexts/authContext';
import * as API from '../../api/narlab';
import useSWR, { mutate } from 'swr';
import { useDispatch } from 'react-redux';
import showNotification from '../../components/extras/showNotification';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalFooter,
} from '../../components/bootstrap/Modal';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import {
	ModelsGetUserScoreHistoryRes,
	ModelsGetUserListRes,
} from '../../swagger/narlab';
import { getFirstLetter, priceFormat } from '../../helpers/helpers';
import { Any } from 'react-spring';

const ManageHistory = () => {
	const dispatch = useDispatch();
	const { user } = useContext(AuthContext);
	const { themeStatus, darkModeStatus } = useDarkMode();
	// const [manageModalStatus, setManageModalStatus] = useState<string | null>(
	// 	null
	// );

	// const [UserList, setUserList] = useState<any>([]);
	const [limitModalStatus, setLimitModalStatus] = useState<string | null>(null);
	const [limitHistoryModalStatus, setLimitHistoryModalStatus] = useState<
		string | null
	>(null);
	const { data: limitData } = useSWR('/limitData', () => API.getLimit());

	const { data: userData = { user: [] } } = useSWR<ModelsGetUserListRes>(
		'/scoreUser',
		() => API.getNarlabUser()
	);
	// BEGIN :: Upcoming Events
	// const [isOpen, setIsOpen] = useState(false);
	const [openRowId, setOpenRowId] = useState<number | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['25']);
	// const [scoreLog, setScoreLog] = useState<ModelsUserScoreLog[] | []>([]);
	const [editHistoryData, setEditHistoryData] =
		useState<ModelsGetUserScoreHistoryRes>();
	const formik = useFormik({
		initialValues: {
			searchInput: '',
			scoreId: '',
			limit: limitData?.uploadLimit,
		},
		validate: (values) => {
			const errors: {
				limit?: string;
			} = {
				limit: undefined,
			};
			if (!values.limit) {
				errors.limit = '請輸入限制次數';
			}
			// if (!values.returnDate) {
			// 	errors.returnDate = '請輸入回傳時間';
			// }
			// if (!values.createDate) {
			// 	errors.createDate = '請輸入上傳時間';
			// }

			//錯誤判斷
			const boardErrors = Object.values(errors).reduce((map, current) => {
				if (current) {
					return true;
				}
				return map;
			}, false);

			if (!boardErrors) {
				return {};
			}
			return errors;
		},

		onSubmit: (values) => {
			// console.log(values);
			API.updateLimit({
				uploadLimit: values.limit || 0,
			})
				.then((res) => {
					if (res === 'success') {
						showNotification(
							<span className="d-flex align-items-center">
								<Icon icon="Info" size="lg" className="me-1" />
								<span>已成功編輯限制次數。</span>
							</span>,
							'success'
						);
						mutate('/limit');
						setLimitModalStatus('');
					}
				})
				.catch((err) => {
					// console.log(err.error.messanger);
					showNotification('錯誤', err.error, 'danger');
				});
		},
	});

	const handleRowClick = (id: number) => {
		setOpenRowId(id === openRowId ? null : id);
	};

	function numberUp(e: number) {
		const roundedNum: number = Math.ceil(e * 100) / 100;
		return roundedNum;
	}

	function handleClickHistory(userId: string) {
		setLimitHistoryModalStatus(userId);
		setEditHistoryData({});
		API.historyList(userId, user.userId || '').then((res) => {
			if (res.userScoreList && res.userScoreList.length > 0) {
				setEditHistoryData(res);
			}
		});
		// console.log(userId);
	}

	const filteredData = editHistoryData?.userScoreList?.filter((f) =>
		f?.filename?.toLowerCase().includes(formik.values.searchInput.toLowerCase())
	);

	const { items, requestSort, getClassNamesFor } = useSortableData(
		filteredData || []
	);

	return (
		<PageWrapper title="管理上傳歷程表">
			<Page>
				<div className="row  h-100">
					<div className="col-12 mt-3">
						<Card stretch>
							<CardHeader borderSize={1}>
								<div className="row  d-flex justify-content-between w-100">
									<div className="col-auto">
										<CardLabel icon="HistoryToggleOff" iconColor="dark">
											<CardTitle>上傳歷程表</CardTitle>
										</CardLabel>
									</div>
									<div className="col-auto">
										<Button
											icon="DateRange"
											color="dark"
											onClick={() => setLimitModalStatus('true')}
										>
											當日上傳限制次數 ({limitData?.uploadLimit})
										</Button>
									</div>
								</div>
							</CardHeader>
							<CardBody isScrollable className="table-responsive">
								<table className="table table-modern ">
									<thead>
										<tr>
											<th className="cursor-pointer text-decoration-underline">
												使用者名稱
												<Icon size="lg" icon="FilterList" />
											</th>

											<th>公司名稱</th>

											<td />
										</tr>
									</thead>
									<tbody>
										{userData.user?.map((item: any) => (
											<tr key={item.userId}>
												<td>
													<div className="d-flex align-items-center">
														<div className="flex-shrink-0">
															<div
																className="ratio ratio-1x1 me-3"
																style={{ width: 48 }}
															>
																<div
																	className={`bg-dark text-light rounded-2 d-flex h2 align-items-center justify-content-center`}
																>
																	<span className="fw-bold">
																		{getFirstLetter(item.userName)}
																	</span>
																</div>
															</div>
														</div>
														<div className="flex-grow-1">
															<div className="fs-6 fw-bold">
																{item.userName}
															</div>
															<div className="text-muted">
																<Icon icon="Work" />{' '}
																<small>{item.userLevel}</small>
															</div>
														</div>
													</div>
												</td>

												<td>
													<div className="fs-6 fw-bold">
														{item.userOrganization}
													</div>
												</td>

												<td>
													<Button
														isOutline={!darkModeStatus}
														color="dark"
														isLight={darkModeStatus}
														className={classNames({
															'border-light': !darkModeStatus,
														})}
														onClick={() => handleClickHistory(item.userId)}
														icon="HistoryToggleOff"
													>
														上傳歷程表
													</Button>
												</td>
											</tr>
										))}
										<tr>
											<td className="p-0 " colSpan={11}>
												{userData.user?.length ? null : (
													<div
														className="mx-auto d-flex justify-content-center "
														style={{
															height: 200,
															padding: 20,
														}}
													>
														<div className="d-flex flex-column  justify-content-center">
															<div style={{ margin: '0 auto' }}>
																<Icon
																	icon="Inbox"
																	color="dark"
																	className="me-1 h1"
																/>
															</div>
															尚未有資料填入
														</div>
													</div>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</CardBody>
						</Card>

						{/* 歷史紀錄 */}
						<Modal
							setIsOpen={setLimitHistoryModalStatus}
							isOpen={limitHistoryModalStatus ? true : false}
							size="xl"
							data-tour="mail-app-modal"
						>
							<ModalHeader
								className="px-4"
								setIsOpen={setLimitHistoryModalStatus}
							>
								<CardLabel icon="DateRange" iconColor="dark">
									<CardTitle>歷史紀錄</CardTitle>
								</CardLabel>
							</ModalHeader>

							<ModalBody className="px-4">
								<table className="table table-modern">
									<thead>
										<tr>
											<th />
											<th className="text-decoration-underline">檔案名稱</th>

											<th
												onClick={() => requestSort('mota')}
												className="text-decoration-underline"
											>
												MOTA
												<Icon
													size="lg"
													className={getClassNamesFor('mota')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idf1')}
												className="text-decoration-underline"
											>
												IDF1
												<Icon
													size="lg"
													className={getClassNamesFor('idf1')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idp')}
												className="text-decoration-underline"
											>
												IDP{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('idp')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('idr')}
												className="text-decoration-underline"
											>
												IDR{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('idr')}
													icon="FilterList"
												/>
											</th>

											<th
												onClick={() => requestSort('prcn')}
												className="text-decoration-underline"
											>
												PRCN{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('prcn')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('far')}
												className="text-decoration-underline"
											>
												FAR{' '}
												<Icon
													size="lg"
													className={getClassNamesFor('far')}
													icon="FilterList"
												/>
											</th>

											<th
												onClick={() => requestSort('returnDate')}
												className="text-decoration-underline"
											>
												RETURN DATE
												<Icon
													size="lg"
													className={getClassNamesFor('returnDate')}
													icon="FilterList"
												/>
											</th>
											<th
												onClick={() => requestSort('createDate')}
												className="text-decoration-underline"
											>
												UPLOAD DATE
												<Icon
													size="lg"
													className={getClassNamesFor('createDate')}
													icon="FilterList"
												/>
											</th>
											<th />
										</tr>
									</thead>
									<tbody>
										{dataPagination(items, currentPage, perPage).map(
											(item, index) => (
												<React.Fragment key={index}>
													<tr>
														<td>
															<Button
																isOutline={!darkModeStatus}
																color="dark"
																isLight={darkModeStatus}
																className={classNames({
																	'border-light': !darkModeStatus,
																})}
																onClick={() => handleRowClick(item.scoreId)}
																icon="Info"
															/>
														</td>
														<td>
															<div className="d-flex flex-column">
																<span className="fw-bold ">
																	{item.filename.split(',')[0]}
																</span>
																<span className="fw-bold ">
																	{item.filename.split(',')[1]}
																</span>
																<span className="fw-bold ">
																	{item.filename.split(',')[2]}
																</span>
															</div>
														</td>

														<td className="h5">
															<Badge color="info">{numberUp(item.mota)}</Badge>
														</td>
														<td className="h5">
															<Badge color="success">
																{numberUp(item.idf1)}
															</Badge>
														</td>
														<td className="h5">
															<Badge color="dark">{numberUp(item.idp)}</Badge>
														</td>
														<td className="h5">
															<Badge color="primary">
																{numberUp(item.idr)}
															</Badge>
														</td>

														<td className="h5">
															<Badge color="warning">
																{numberUp(item.prcn)}
															</Badge>
														</td>
														<td className="h5">
															<Badge color="secondary">
																{numberUp(item.far)}
															</Badge>
														</td>

														<td>
															<div className="d-flex flex-column">
																{/* <span className="visually-hidden">
												{item.status.name}
											</span> */}
																<span>{item.returnDate.split(' ')[0]}</span>
																<span>{item.returnDate.split(' ')[1]}</span>
															</div>
														</td>

														<td>
															<div className="d-flex flex-column">
																{/* <span className="visually-hidden">
												{item.status.name}
											</span> */}

																<span>{item.createDate.split(' ')[0]}</span>
																<span>{item.createDate.split(' ')[1]}</span>
															</div>
														</td>

														<td></td>
													</tr>
													<tr>
														{openRowId === item.scoreId && (
															<td colSpan={12} className="p-0">
																<table className="w-100 table-modern m-0">
																	<thead>
																		<tr>
																			<th />

																			<th className="text-center">
																				GT
																				{/* Ground Truth */}
																			</th>
																			<th className="text-center">
																				MT
																				{/* Mostly Tracked */}
																			</th>
																			<th className="text-center">
																				PT
																				{/* Partially Tracked */}
																			</th>
																			<th className="text-center">
																				ML
																				{/* Mostly Lost */}
																			</th>

																			<th className="text-center">
																				FP
																				{/* False Positives */}
																			</th>
																			<th className="text-center">
																				FN
																				{/* False Negatives */}
																			</th>
																			<th className="text-center">
																				IDs
																				{/* Identity Switches */}
																			</th>
																			<th className="text-center">
																				FM
																				{/* Fragmentations */}
																			</th>

																			<th className="text-center">RCLL</th>
																			<th className="text-center">
																				MOTAL
																				{/* Multiple Object Tracking Accuracy Lower
																				Bound */}
																			</th>
																			<th className="text-center">
																				MOTP
																				{/* Multiple Object Tracking Precision */}
																			</th>

																			<th />
																		</tr>
																	</thead>
																	<tbody>
																		<tr>
																			<td></td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.gt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.mt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.pt)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.ml)}
																				</Badge>
																			</td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fp)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fn)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.ids)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.fm)}
																				</Badge>
																			</td>

																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.rcll)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.motal)}
																				</Badge>
																			</td>
																			<td className="h5 text-center">
																				<Badge color="dark">
																					{numberUp(item.motp)}
																				</Badge>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</td>
														)}
													</tr>
												</React.Fragment>
											)
										)}

										<tr>
											<td className="p-0 " colSpan={11}>
												{dataPagination(items, currentPage, perPage)
													.length ? null : (
													<div
														className="mx-auto d-flex justify-content-center "
														style={{
															height: 200,
															padding: 20,
														}}
													>
														<div className="d-flex flex-column  justify-content-center">
															<div style={{ margin: '0 auto' }}>
																<Icon
																	icon="Inbox"
																	color="dark"
																	className="me-1 h1"
																/>
															</div>
															No Rows To show
														</div>
													</div>
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</ModalBody>
						</Modal>
						{/* 編輯限制次數 */}
						<Modal
							setIsOpen={setLimitModalStatus}
							isOpen={limitModalStatus ? true : false}
							size="sm"
							data-tour="mail-app-modal"
						>
							<ModalHeader className="px-4" setIsOpen={setLimitModalStatus}>
								<CardLabel icon="DateRange" iconColor="dark">
									<CardTitle>編輯當日上傳限制次數</CardTitle>
								</CardLabel>
							</ModalHeader>

							<ModalBody className="px-4">
								<CardBody>
									<form className="row g-4">
										<div className="col-12">
											<FormGroup id="limit" isFloating label={'限制次數'}>
												<Input
													type="number"
													autoComplete="limit"
													value={formik.values.limit}
													isTouched={formik.touched.limit}
													invalidFeedback={formik.errors.limit}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													onFocus={() => {
														formik.setErrors({});
													}}
												/>
											</FormGroup>
										</div>
									</form>
								</CardBody>
							</ModalBody>
							<ModalFooter>
								<div className="col-12">
									<Button
										color="dark"
										type="submit"
										onClick={formik.handleSubmit}
										className="w-100 py-3"
									>
										儲存
									</Button>
								</div>
							</ModalFooter>
						</Modal>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ManageHistory;
