import { Api as narlabApis } from '../swagger/narlab';
import Bottleneck from 'bottleneck';

const limiter = new Bottleneck({
	maxConcurrent: 1,
	minTime: 333,
});

export const narlabAPI = new narlabApis({
	baseUrl: 'https://narlab-comptitive-backend-dot-ramate-crm.de.r.appspot.com',
	customFetch(input, init?) {
		return limiter.schedule(() => fetch(input, { ...init }));
	},
});
