import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Checks from '../../components/bootstrap/forms/Checks';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import InputGroup, {
	InputGroupText,
} from '../../components/bootstrap/forms/InputGroup';
import Button from '../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import Label from '../../components/bootstrap/forms/Label';
import * as API from '../../api/narlab';
import useSWR, { mutate } from 'swr';
import AuthContext from '../../contexts/authContext';
import { v4 as uuidv4 } from 'uuid';
// import { useWindowSize } from 'react-use';
// import './style.css';
// import tinymce from 'tinymce/tinymce';
// import { base64ToArrayBuffer, getArrayBuffer } from '../../common/format';

const ManagePage = () => {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const [content, setContent] = useState('');
	const [editContent, setEditContent] = useState('');
	const [newPageId, setNewPageId] = useState(
		uuidv4().replace(/-/g, '').toString().toUpperCase()
	);

	const [text, setText] = useState('');
	const [images, setImages] = useState<string[]>([]);
	const { pageId } = useParams();
	const [loaded, setLoaded] = useState(false);
	// const windowsHeight = useWindowSize().height;

	// const [height, setHeight] = useState(windowsHeight - 550);

	const { data: data, error = '' } = useSWR(
		pageId && `/page-update/${pageId}`,
		() => API.pageDetail(pageId || '', user.userId || ''),
		{
			revalidateOnMount: true,
			revalidateOnFocus: false,
			onSuccess: (res) => {
				if (loaded) return;
				// console.log(res.pageMediaList);
				// formik.setFieldValue('欄位名',資料)

				formik.setValues({
					pageName: res.pageName || '',
					pagePath: res.pagePath || '',
					pageButton: res.pageButton || '',
					pageStatus: res.pageStatus === '1' ? true : false,
				});

				setEditContent(res.pageContent || '');

				setLoaded(true);
				// console.log(res);
			},
			// refreshInterval: 1,
			onError: (err) => {
				// console.log(err);
			},
		}
	);

	// const handleFilePicker = (callback: any, value: any, meta: any) => {
	// 	const input = document.createElement('input');
	// 	input.setAttribute('type', 'file');
	// 	input.setAttribute('accept', meta.filetype);
	// 	input.onchange = () => {
	// 		const file = input.files![0];
	// 		const reader = new FileReader();
	// 		reader.onload = (e: any) => {
	// 			const blobUrl = URL.createObjectURL(file);
	// 			callback(blobUrl, { title: file.name });
	// 		};
	// 		reader.readAsDataURL(file);
	// 	};
	// 	input.click();
	// };

	const formik = useFormik({
		initialValues: {
			pageName: '',
			pagePath: '',
			pageButton: '',
			pageStatus: true,
		},
		validate: (values) => {
			const errors: {
				pageName?: string;
				pagePath?: string;
				pageButton?: string;
				pageStatus?: boolean;
			} = {
				pageName: undefined,
				pagePath: undefined,
				pageButton: undefined,
				pageStatus: undefined,
			};

			if (!values.pageName) {
				errors.pageName = '請輸入文章名稱';
			}

			if (!values.pageButton) {
				errors.pageButton = '請輸入文章按鈕';
			}

			if (values.pageButton.length > 10) {
				errors.pageButton = '文章按鈕不得超過10字元';
			}

			if (!values.pagePath && pageId != '11EDE255C0B2CD23A5F042010A5AC009') {
				errors.pagePath = '請輸入識別碼';
			}

			if (
				/[^\u4E00-\u9FA5|\d|\a-zA-Z|\r\n\s,.?!，。？！…—&$=()-+/*{}[\]]/g.test(
					values.pagePath
				)
			) {
				errors.pagePath = '請輸入正確網址格式';
			}

			if (/[\u4e00-\u9fa5]/gi.test(values.pagePath)) {
				errors.pagePath = '請勿輸入中文';
			}

			//錯誤判斷
			const pageErrors = Object.values(errors).reduce((map, current) => {
				if (current) {
					return true;
				}
				return map;
			}, false);

			if (!pageErrors) {
				return {};
			}
			return errors;
		},

		onSubmit: (values) => {
			// console.log(values);
			if (pageId) {
				//編輯API
				API.pageUpdate({
					pageId: pageId,
					pageMediaList: images,
					execManagerId: user.userId || '',
					pageContent: editContent,
					pageButton: values.pageButton,
					pagePath: values.pagePath,
					pageName: values.pageName,
					pageStatus: values.pageStatus.toString() === 'true' ? '1' : '0',
				})
					.then((res) => {
						// console.log(res);
						if (res === 'success') {
							showNotification(
								<span className="d-flex align-items-center">
									<Icon icon="Info" size="lg" className="me-1" />
									<span>已成功修改 {values.pageName} 的文章。</span>
								</span>,
								'',
								// 'The web page have been successfully updated.'
								'success'
							);
							navigate('/admin/page');
							mutate('/page');
						}
					})
					.catch((err) => {
						// console.log(err.error.messanger);
						showNotification('錯誤', err.error, 'danger');
					});
			} else {
				//新增API
				API.pageCreate({
					execManagerId: user.userId || '',
					pageContent: editContent,
					pageId: newPageId,
					pagePath: values.pagePath,
					pageButton: values.pageButton,
					pageName: values.pageName,
					pageStatus: values.pageStatus === true ? '1' : '0',
				})
					.then((res) => {
						if (res === 'success') {
							showNotification(
								<span className="d-flex align-items-center">
									<Icon icon="Info" size="lg" className="me-1" />
									<span>已建立一筆 {values.pageName} 的文章。</span>
								</span>,
								'',
								// 'The web page have been successfully added.'
								'success'
							);

							navigate('/admin/page');
							mutate('/page');
						}
					})
					.catch((err) => {
						// console.log(err.error);
						showNotification('錯誤', err.error, 'danger');
					});
			}
		},
	});

	return (
		<div className="container">
			<div className="row ">
				<div className="col-12 mt-4 mb-4">
					<Button
						color="dark"
						isLight
						size={'sm'}
						icon="ArrowBack"
						onClick={() => navigate('/admin/page')}
					>
						返回列表
					</Button>
				</div>
				<div className="col-12">
					<Card>
						<form onSubmit={formik.handleSubmit} noValidate>
							<CardHeader>
								<CardLabel icon="NoteAdd" iconColor="info">
									<CardTitle>文章內文</CardTitle>
								</CardLabel>
								<CardActions>
									<FormGroup id="pageStatus" isFloating>
										<Checks
											color="dark"
											label="文章狀態"
											// value={formik.values.pageStatus}
											onChange={formik.handleChange}
											checked={formik.values.pageStatus}
											type="switch"
										/>
									</FormGroup>
								</CardActions>
							</CardHeader>
							<CardBody>
								<div className="row g-3 pb-4">
									<div className="col-6 col-xl-3">
										<FormGroup id="pageName" label="文章名稱：">
											<Input
												onChange={formik.handleChange}
												value={formik.values.pageName}
												placeholder="文章名稱"
												isValid={formik.isValid}
												invalidFeedback={formik.errors?.pageName}
												isTouched={formik.touched?.pageName}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className="col-6 col-xl-3">
										<FormGroup id="pageButton" label="文章按鈕：">
											<Input
												type="text"
												onChange={formik.handleChange}
												value={formik.values.pageButton}
												placeholder="文章按鈕"
												isValid={formik.isValid}
												invalidFeedback={formik.errors?.pageButton}
												isTouched={formik.touched?.pageButton}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className="col-12 col-xl-6">
										<FormGroup>
											<Label htmlFor="exampleUrl">識別碼：</Label>
											<InputGroup>
												<InputGroupText id="addon3">
													https://darkors.com/
												</InputGroupText>
												<Input
													id="pagePath"
													ariaDescribedby="addon3"
													onChange={formik.handleChange}
													value={formik.values.pagePath}
													invalidFeedback={formik.errors?.pagePath}
													isTouched={formik.touched?.pagePath}
													onBlur={formik.handleBlur}
												/>
											</InputGroup>
										</FormGroup>
									</div>
								</div>

								<Editor
									apiKey="h34i2y70eywi6muuy9iqteqw2i216q35zep9fprczen9ttgv"
									// initialValue="<p>請在此編輯您的文章</p>"
									// value={content}
									value={editContent}
									onInit={(evt, editor) => {
										setText(editor.getContent({ format: 'text' }));
									}}
									onEditorChange={(newValue, editor) => {
										setEditContent(newValue);
										setText(editor.getContent({ format: 'text' }));
									}}
									init={{
										// skin: 'snow',
										// icons: 'thin',
										height: 'auto',
										placeholder: '請在此編輯您的文章...',
										language: 'zh_TW',
										plugins:
											' preview searchreplace autolink directionality visualblocks visualchars fullscreen link media template code codesample table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help emoticons autosave autoresize quickbars ',

										toolbar:
											'undo redo | fontfamily fontsize blocks | forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent code | \
										styleselect formatselect fontselect fontsizeselect | table quickimage media charmap emoticons pagebreak fullscreen | preview  ',

										// min_height: '400',
										fontsize_formats:
											'12px 14px 16px 18px 24px 36px 48px 56px 72px',
										font_formats:
											'微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
										file_picker_types: 'image',
										quickbars_insert_toolbar: false,
										block_unsupported_drop: false,
										images_file_types: 'jpg,svg,webp',
										// file_picker_callback: handleFilePicker,
										// images_upload_handler: (blobInfo, progress) =>
										// 	new Promise(async (resolve, reject) => {
										// 		// console.log('successsssssssss');
										// 		// console.log(blobInfo);
										// 		// console.log(blobInfo.base64());
										// 		const b64 = blobInfo.base64();

										// 		const extension =
										// 			'.' + blobInfo.filename().split('.')[1];
										// 		// const arr =  await base64ToArrayBuffer(b64)

										// 		API.mediaCreate({
										// 			execManagerId: user.userId || '',
										// 			extension: extension,
										// 			media: b64,
										// 			pageId: pageId || '',
										// 		}).then((res) => {
										// 			resolve(res);
										// 		});
										// 	}),
										// file_picker_callback: handleFilePicker,
										file_picker_callback: (callback, value, meta) => {
											// Provide image and alt text for the image dialog
											if (meta.filetype == 'image') {
												callback('myimage.jpg', { alt: 'My alt text' });
											}
										},
										images_upload_handler: (blobInfo, progress) =>
											new Promise(async (resolve, reject) => {
												const formData = new FormData();
												formData.append(
													'file',
													blobInfo.blob(),
													blobInfo.filename()
												);
												const file = formData.get('file') as File;

												const extension =
													'.' + blobInfo.filename().split('.')[1];
												// const arr =  await base64ToArrayBuffer(b64)
												if (pageId) {
													API.mediaCreate({
														execManagerId: user.userId || '',
														extension: extension,
														media: file,
														pageId: pageId || '',
													}).then((res) => {
														resolve(res);
													});
												} else {
													API.mediaCreate({
														execManagerId: user.userId || '',
														extension: extension,
														media: file,
														pageId: newPageId || '',
													}).then((res) => {
														resolve(res);
													});
												}
											}),
										setup: function (editor) {
											editor.on('change', function () {
												var images = [];
												var html = editor.getContent();
												var parser = new DOMParser();
												var doc = parser.parseFromString(html, 'text/html');

												var imgElements = doc.getElementsByTagName('img');
												images = [];
												for (var i = 0; i < imgElements.length; i++) {
													var img = imgElements[i];
													var src = img.getAttribute('src');
													if (src) {
														images.push(src);
													}
												}
												// console.log('照片紀錄', images);
												setImages(images);
											});
										},
										// content_style: `body { 	height: calc(100vh - 257px)  ;overflow-y: scroll !important;} `,
										quickbars_selection_toolbar: 'quickimage quicktable',
										toolbar_sticky: true,
										autosave_ask_before_unload: false,
										contextmenu: 'link image table',
										autocorrect_capitalize: true,
									}}
								/>
							</CardBody>
							<CardFooter>
								<Button
									color="dark"
									type="submit"
									icon="Save"
									className="w-100"
								>
									{pageId ? '儲存' : '新增文章'}
								</Button>
							</CardFooter>
						</form>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default ManagePage;
