import React, { useContext, useState, useEffect } from 'react';
import Page from '../../layout/Page/Page';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Badge from '../../components/bootstrap/Badge';
import Button from '../../components/bootstrap/Button';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { TCards } from '../../pages/presentation/project-management/type/types';
import {
	move,
	reorder,
} from '../../pages/presentation/project-management/helper/helper';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import Board from '../../pages/presentation/project-management/component/Board';
import Columns from '../../pages/presentation/project-management/component/Columns';
import useSWR, { mutate } from 'swr';
import * as API from '../../api/narlab';
import showNotification from '../../components/extras/showNotification';
import AuthContext from '../../contexts/authContext';

const ManageNavbars = () => {
	const { user } = useContext(AuthContext);
	const [navDataList, setNavDataList] = useState<any>([]);
	// const fetcher = () => API.getNav();
	// const [navCount, setNavCount] = useState<number>(1);
	// const [navListCount, setNavListCount] = useState<number>(1);
	// const [shouldFetch, setShouldFetch] = React.useState<boolean>();
	// const fetcher = () =>
	// 	new Promise((resolve) => {
	// 		setTimeout(() => resolve('test'), 3000);
	// 	});

	useEffect(() => {
		mutate('/nav');
		mutate('/navList');
	}, []);

	const { data: navData = { nav: {} }, error = '' } = useSWR(
		'/nav',
		() => API.getNav(),
		{
			onSuccess: (data) => {
				setNavDataList(data.nav);
			},
			revalidateOnMount: true,
			revalidateOnFocus: false,
		}
	);

	const { data: navListData = { nav: [] } } = useSWR(
		'/navList',
		() => API.getNavList(),
		{
			onSuccess: (data) => {
				setState(data.nav);
			},
			revalidateOnMount: true,
			revalidateOnFocus: false,
		}
	);

	function handleClickSave(state: TCards) {
		const submitNav = Object.keys(state).reduce((map, current) => {
			if (!navDataList) return { ...map };
			const navId = navDataList[current].pageNavId;
			const navName = navDataList[current].pageNavName;

			const col = {
				page: state[current],
				pageNavId: navId,
				pageNavName: navName,
			};
			return {
				...map,
				[current]: col,
			};
		}, {});
		API.updateNav({
			execManagerId: user.userId || '',
			nav: submitNav,
		})
			.then((res) => {
				if (res === 'success') {
					showNotification(
						<span className="d-flex align-items-center">已成功儲存欄位</span>,
						// 'The web page have been successfully updated.',
						'success'
					);
					mutate('/nav');
					mutate('/navList');
				}
			})
			.catch((err) => {
				showNotification('錯誤', err.error, 'danger');
			});
	}
	const [state, setState] = useState<any>([]);

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const ITEMS = reorder(
				state[source.droppableId],
				source.index,
				destination.index
			);

			const sourceList = source.droppableId;
			setState({ ...state, [sourceList]: ITEMS });
		} else {
			const RESULT = move(
				state[source.droppableId],
				state[destination.droppableId],
				source,
				destination
			);

			setState({
				...state,
				...RESULT,
			});
		}
	};

	return (
		<PageWrapper title="管理導覽列">
			<form>
				<div className="mt-3">
					<SubHeader>
						<SubHeaderLeft>
							<span>
								請將文章圖卡拖動至&nbsp;&nbsp;
								<Badge color="info" isLight>
									導覽列中
								</Badge>
								&nbsp; 以及按下 &nbsp;<Badge color="dark"> 儲存導覽列 </Badge>
								&nbsp;按鈕。
							</span>
						</SubHeaderLeft>

						<SubHeaderRight>
							<SubheaderSeparator />
							<Button
								color="dark"
								icon="Save"
								// type="submit"
								onClick={() => {
									handleClickSave(state);
									// console.log(state);
								}}
							>
								儲存導覽列
							</Button>
							{/* <Button
                color="dark"
                icon="Alert"
                onClick={() => {
                  console.log(state);
                }}
              >
                console
              </Button> */}
						</SubHeaderRight>
					</SubHeader>
				</div>
				<Page container="fluid">
					<DragDropContext onDragEnd={onDragEnd}>
						<Board>
							<Columns
								columnsData={navDataList}
								cardsData={state}
								setCardsData={setState}
							/>
						</Board>
					</DragDropContext>
				</Page>
			</form>
		</PageWrapper>
	);
};

export default ManageNavbars;
