import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPages } from '../../menu';
// import {
// 	DropdownItem,
// 	DropdownMenu,
// } from '../../components/bootstrap/Dropdown';
// import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import { useDispatch, useSelector } from 'react-redux';
// import { RootState } from 'typesafe-actions';
import * as API from '../../api/narlab';
import showNotification from '../../components/extras/showNotification';
import UserImg from '../../assets/img/user6.png';

const User = () => {
	const { user, setUser } = useContext(AuthContext);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role="presentation"
				onClick={() => setCollapseStatus(!collapseStatus)}
			>
				<div className="user-avatar">
					<img src={UserImg} alt="Avatar" width={128} height={128} />
				</div>
				<div className="user-info">
					<div className="user-name d-flex align-items-center">
						{/* {`${userData?.firmInfo?.name} ${userData?.userInfo?.userName}`} */}
						{user.userName}
						{/* <br />
						{userData?.userInfo?.userName} */}
						<Icon icon="Verified" className="ms-1" color="info" />
					</div>
					{/* <div className="user-sub-title">{user.userOrganization}</div> */}
				</div>
			</div>
			{/* <DropdownMenu>
      <DropdownItem>
          <Button
            icon="AccountBox"
            onClick={() =>
              navigate(
                `../${demoPages.appointment.subMenu.employeeID.path}/${userData?.userInfo?.userId}`
              )
            }
          >
            Profile
          </Button>
        </DropdownItem> 
  <DropdownItem>
          <Button
            icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
            onClick={() => setDarkModeStatus(!darkModeStatus)}
            aria-label="Toggle fullscreen"
          >
            {darkModeStatus ? 'Dark Mode' : 'Light Mode'}
          </Button>
        </DropdownItem> 
      </DropdownMenu> */}

			<Collapse isOpen={collapseStatus} className="user-menu">
				<nav aria-label="aside-bottom-user-menu">
					<div className="navigation">
						{/* <div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${demoPages.appointment.subMenu.employeeID.path}/${userData?.userInfo?.userId}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Profile')}</span>
								</span>
							</span>
						</div> */}
						{/* <div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/settings`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Settings' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Settings')}</span>
								</span>
							</span>
						</div> */}
						{/* <div
							role="presentation"
							className="navigation-item cursor-pointer"
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}
						>
							<span className="navigation-link navigation-link-pill">
								<span className="navigation-link-info">
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className="navigation-icon"
									/>
									<span className="navigation-text">
										{darkModeStatus ? t('menu:DarkMode') : t('menu:LightMode')}
									</span>
								</span>
							</span>
						</div> */}
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label="aside-bottom-user-menu-2">
					<div className="navigation">
						<div
							role="presentation"
							className="navigation-item cursor-pointer"
							onClick={() => {
								showNotification('提示', '登出成功', 'success');
								if (user && user.userId) {
									const userId = user.userId || ('' as string);
									API.managerLogout(userId)
										.then(() => {
											navigate(`/`);
											localStorage.removeItem('narlab');
											if (setUser) {
												setUser({});
											}
										})
										.catch(() => {
											navigate(`/`);
										});
								}
							}}
						>
							<span className="navigation-link navigation-link-pill">
								<span className="navigation-link-info">
									<Icon icon="Logout" className="navigation-icon" />
									<span className="navigation-text">{t('menu:Logout')}</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
