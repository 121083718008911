import {
	ModelsCreatePageReq,
	ModelsUpdatePageReq,
	ModelsSendConfirmUserEmailReq,
	ModelsSendUserForgetPasswordEmailReq,
	ModelsUserForgetPasswordReq,
	ModelsConfirmUserReq,
	ModelsUpdateNavReq,
	ModelsCreateNavReq,
	MediaCreatePayload,
	ModelsDeletePageMediaReq,
	ModelsUpdateManagerReq,
	ModelsCreateManagerReq,
	ModelsDeleteManagerReq,
	ModelsManagerAutoLoginReq,
	ModelsManagerLoginReq,
	ModelsUpdateUserReq,
	ModelsCreateUserReq,
	ModelsUserAutoLoginReq,
	ModelsUserLoginReq,
	ModelsUpdateNavNameReq,
	ModelsUpdateUserScoreReq,
	ModelsCreateUserScoreReq,
	ModelsDeleteUserScoreReq,
	ModelsUpdateUploadLimitReq,
	ModelsGetUserScoreListReq,
	ModelsSendManagerSetPasswordReq,
	ModelsManagerSetPasswordReq,
} from '../swagger/narlab';
import { narlabAPI } from './base';

export const getListDetail = async (execManagerId: string) => {
	const { data } = await narlabAPI.page.listDetail(execManagerId);

	return data;
};

export const pageCreate = async (req: ModelsCreatePageReq) => {
	const { data } = await narlabAPI.page.pageCreate(req);
	return data;
};

export const pageDetail = async (pageId: string, execManagerId: string) => {
	const { data } = await narlabAPI.page.pageDetail(pageId, execManagerId);

	return data;
};

export const pageListDetail = async (execManagerId: string) => {
	const { data } = await narlabAPI.page.listDetail(execManagerId);

	return data;
};

export const pageUpdate = async (req: ModelsUpdatePageReq) => {
	// console.log('this is req:  ', req);
	const { data } = await narlabAPI.page.pageUpdate(req);

	return data;
};

export const pageDelete = async (pageId: string, execManagerId: string) => {
	const { data } = await narlabAPI.page.pageDelete(pageId, execManagerId);

	return data;
};

export const getNarlabRouter = async () => {
	const { data } = await narlabAPI.page.routerList();

	return data;
};

export const mediaCreate = async (req: MediaCreatePayload) => {
	const { data } = await narlabAPI.page.mediaCreate(req);

	return data;
};

export const mediaDelete = async (req: ModelsDeletePageMediaReq) => {
	const { data } = await narlabAPI.page.mediaDelete(req);

	return data;
};

export const sendConfirmUserEmail = async (
	req: ModelsSendConfirmUserEmailReq
) => {
	const { data } = await narlabAPI.email.confirmCreate(req);

	return data;
};

export const sendManagerPasswordEmail = async (
	req: ModelsManagerSetPasswordReq
) => {
	const { data } = await narlabAPI.manager.passwordCreate(req);

	return data;
};

export const sendPasswordEmail = async (
	req: ModelsSendUserForgetPasswordEmailReq
) => {
	const { data } = await narlabAPI.email.passwordCreate(req);

	return data;
};

export const confirmUser = async (req: ModelsConfirmUserReq) => {
	const { data } = await narlabAPI.user.confirmCreate(req);

	return data;
};

export const forgetPasswordChange = async (
	req: ModelsUserForgetPasswordReq
) => {
	const { data } = await narlabAPI.user.passwordCreate(req);

	return data;
};

export const getFrontendNav = async () => {
	const { data } = await narlabAPI.frontend.getFrontend();

	return data;
};

export const getFrontendDetail = async (pageId: string) => {
	const { data } = await narlabAPI.frontend.frontendDetail(pageId);

	return data;
};

export const updateNav = async (req: ModelsUpdateNavReq) => {
	const { data } = await narlabAPI.nav.putNav(req);

	return data;
};

export const updateNavName = async (req: ModelsUpdateNavNameReq) => {
	const { data } = await narlabAPI.nav.nameUpdate(req);

	return data;
};

export const addNav = async (req: ModelsCreateNavReq) => {
	const { data } = await narlabAPI.nav.postNav(req);

	return data;
};

export const deleteNav = async (navId: string, execManagerId: string) => {
	const { data } = await narlabAPI.nav.deleteNav(navId, execManagerId);

	return data;
};

export const getNav = async () => {
	const { data } = await narlabAPI.nav.getNav();

	return data;
};

export const getNavList = async () => {
	const { data } = await narlabAPI.nav.pageList();

	return data;
};

export const managerUpdate = async (req: ModelsUpdateManagerReq) => {
	const { data } = await narlabAPI.manager.managerUpdate(req);

	return data;
};

export const managerCreate = async (req: ModelsCreateManagerReq) => {
	const { data } = await narlabAPI.manager.managerCreate(req);

	return data;
};

export const managerEmailCreate = async (
	req: ModelsSendManagerSetPasswordReq
) => {
	const { data } = await narlabAPI.email.managerCreate(req);

	return data;
};

export const managerDelete = async (req: ModelsDeleteManagerReq) => {
	const { data } = await narlabAPI.manager.managerDelete(req);

	return data;
};

export const managerAutoLogin = async (req: ModelsManagerAutoLoginReq) => {
	const { data } = await narlabAPI.manager.autoLoginCreate(req);

	return data;
};

export const managerList = async (managerId: string) => {
	const { data } = await narlabAPI.manager.listDetail(managerId);

	return data;
};

export const managerLogin = async (req: ModelsManagerLoginReq) => {
	const { data } = await narlabAPI.manager.loginCreate(req);

	return data;
};

export const managerLogout = async (managerId: string) => {
	const { data } = await narlabAPI.manager.logoutDelete(managerId);

	return data;
};

export const managerDetail = async (managerId: string) => {
	const { data } = await narlabAPI.manager.managerDetail(managerId);

	return data;
};

export const narlabUserUpdate = async (req: ModelsUpdateUserReq) => {
	const { data } = await narlabAPI.user.userUpdate(req);

	return data;
};

export const narlabUserCreate = async (req: ModelsCreateUserReq) => {
	const { data } = await narlabAPI.user.userCreate(req);

	return data;
};

export const narlabUserAutoLogin = async (req: ModelsUserAutoLoginReq) => {
	const { data } = await narlabAPI.user.autoLoginCreate(req);

	return data;
};

export const getNarlabUser = async () => {
	const { data } = await narlabAPI.user.listList();

	return data;
};

export const narlabUserLogin = async (req: ModelsUserLoginReq) => {
	const { data } = await narlabAPI.user.loginCreate(req);

	return data;
};

export const narlabUserLogout = async (userId: string) => {
	const { data } = await narlabAPI.user.logoutDelete(userId);

	return data;
};

export const getNarlabUserDetail = async (userId: string) => {
	const { data } = await narlabAPI.user.userDetail(userId);

	return data;
};

export const narlabUserDelete = async (userId: string) => {
	const { data } = await narlabAPI.user.userDelete(userId);

	return data;
};

export const scoreUpdate = async (res: ModelsUpdateUserScoreReq) => {
	const { data } = await narlabAPI.score.scoreUpdate(res);

	return data;
};

export const scoreCreate = async (res: ModelsCreateUserScoreReq) => {
	const { data } = await narlabAPI.score.scoreCreate(res);

	return data;
};

export const scoreDelete = async (res: ModelsDeleteUserScoreReq) => {
	const { data } = await narlabAPI.score.scoreDelete(res);

	return data;
};

export const getScoreList = async (res: ModelsGetUserScoreListReq) => {
	const { data } = await narlabAPI.score.listCreate(res);

	return data;
};

export const getFrontendScoreList = async () => {
	const { data } = await narlabAPI.frontend.scoreList();
	return data;
};

export const getUserScore = async (scorId: string, execManagerId: string) => {
	const { data } = await narlabAPI.score.scoreDetail(scorId, execManagerId);

	return data;
};

export const getUserScoreLog = async (
	scoreId: string,
	execManagerId: string
) => {
	const { data } = await narlabAPI.scoreLog.scoreLogDetail(
		scoreId,
		execManagerId
	);

	return data;
};

export const getUserFileHistory = async (scoreId: string) => {
	const { data } = await narlabAPI.frontend.scoreLogDetail(scoreId);
	return data;
};
export const userFile = async (req: any) => {
	const { data } = await narlabAPI.user.fileCreate(req);
	return data;
};

export const getLimit = async () => {
	const { data } = await narlabAPI.manager.limitList();
	return data;
};

export const updateLimit = async (req: ModelsUpdateUploadLimitReq) => {
	const { data } = await narlabAPI.manager.limitUpdate(req);
	return data;
};

export const historyList = async (userId: string, execManagerId: string) => {
	const { data } = await narlabAPI.score.historyDetail(userId, execManagerId);
	return data;
};
