import React, { FC, useState } from 'react';

import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import classNames from 'classnames';

import Badge from '../../../../components/bootstrap/Badge';

import { TCard, TCards, TColumnsData } from '../type/types';

interface IColumnCard {
	columnKey: string;
	columnsData: TColumnsData;
	card: TCard;
	cardsData: TCards;
	setCardsData(...args: unknown[]): unknown;
	index: number;
}
const ColumnCard: FC<IColumnCard> = ({
	columnKey,
	columnsData,
	card,
	cardsData,
	setCardsData,
	index,
}) => {
	// const { darkModeStatus } = useDarkMode();
	// const [editModalStatus, setEditModalStatus] = useState<boolean>(false);

	// const formik = useFormik({
	// 	initialValues: {
	// 		cardName: card.title || '',
	// 		groupId: columnKey || '',
	// 		description: card.description || '',
	// 		assignee: card.user.username || '',
	// 		task:
	// 			(card.tasks &&
	// 				card.tasks.filter((f) => f.status).map((m) => m.id.toString())) ||
	// 			[],
	// 		tags: (card.tags && card.tags.map((m) => m.id)) || [],
	// 	},
	// 	onSubmit: (values) => {
	// 		const RESULT = move(
	// 			cardsData[columnKey],
	// 			cardsData[formik.values.groupId],
	// 			{
	// 				index: index,
	// 				droppableId: columnKey,
	// 			},
	// 			{ index: 0, droppableId: values.groupId }
	// 		);
	// 		setCardsData({ ...cardsData, ...RESULT });
	// 		setEditModalStatus(false);
	// 	},
	// });

	return (
		<>
			<CardHeader>
				<CardLabel>
					<CardTitle
						tag="h3"
						className={classNames('cursor-pointer', 'link-dark')}
						data-tour={card.pageName}
					>
						<Badge color="info" rounded={1}>
							{card.pageButton}
						</Badge>
					</CardTitle>

					<CardSubTitle className="text-muted">/{card.pagePath}</CardSubTitle>
				</CardLabel>
			</CardHeader>

			<CardBody className="pt-0 h4">{card.pageName}</CardBody>
		</>
	);
};

export default ColumnCard;
