import React, { FC, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api/narlab';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}

interface ILoginProps {
	isSignUp?: boolean;
}
const Check: FC<ILoginProps> = ({ isSignUp }) => {
	const { userId, userToken } = useParams();
	const { t } = useTranslation(['auth']);
	const { darkModeStatus } = useDarkMode();
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const navigate = useNavigate();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			newPassword: '',
			rePassword: '',
		},
		validate: (values) => {
			const errors: { newPassword?: string; rePassword?: string } = {};

			if (!values.newPassword) {
				errors.newPassword = 'Required';
			}

			if (/[\u4e00-\u9fa5]/gi.test(values.newPassword)) {
				errors.newPassword = 'Required';
			}

			if (values.newPassword !== values.rePassword) {
				errors.rePassword = 'Passwords did not match';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			if (!userId || !userToken) return;
			API.forgetPasswordChange({
				newPassword: values.newPassword,
				userId: userId,
				userToken: userToken,
			})
				.then((res) => {
					showNotification(
						'Success',
						'Password changed successfully',
						'success'
					);
					navigate('/login');
				})
				.catch((err) => {
					showNotification('Errors', err.error, 'danger');
					formik.setFieldError('newPassword', err.error.message);
				});
		},
	});

	const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
		return (
			<>
				<div className="text-center h1 fw-bold mt-3">Change Password</div>
				<div className="text-center h4 text-muted mb-3">Change Password</div>
			</>
		);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? t('Sign Up') : t('Login')}
			className={classNames({
				'bg-light': !singUpStatus,
				'bg-dark': singUpStatus,
			})}
		>
			<Page className="p-0">
				<div className="row h-100 align-items-center justify-content-center">
					<div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
						<Card
							className={classNames({
								'shadow-3d-dark': !singUpStatus,
								'shadow-3d-light': singUpStatus,
							})}
							data-tour="login-page"
						>
							<CardBody>
								<div className="text-center my-1">
									<Icon icon="AccountCircle" size="4x" />
									<Link
										to="/"
										className={classNames(
											'text-decoration-none  fw-bold display-6',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											}
										)}
									>
										<br />
										Fish-Eye Traffic Data Competition Website
									</Link>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className="row g-4">
									<div className="col-12">
										<FormGroup id="newPassword" isFloating label="New password">
											<Input
												type="password"
												autoComplete="password"
												value={formik.values.newPassword}
												isTouched={formik.touched.newPassword}
												invalidFeedback={formik.errors.newPassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className="col-12">
										<FormGroup id="rePassword" isFloating label="Confirm">
											<Input
												type="password"
												autoComplete="re-password"
												value={formik.values.rePassword}
												isTouched={formik.touched.rePassword}
												invalidFeedback={formik.errors.rePassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className="col-12">
										<Button
											color="warning"
											type="submit"
											className="w-100 py-3"
											onClick={formik.handleSubmit}
										>
											{t('Change Password')}
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Check.propTypes = {
	isSignUp: PropTypes.bool,
};
Check.defaultProps = {
	isSignUp: false,
};

export default Check;
