import React, { FC, useCallback, useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api/narlab';
import { useDispatch } from 'react-redux';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { user, setUser } = useContext(AuthContext);
	const { t } = useTranslation(['auth']);
	const dispatch = useDispatch();
	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/board'), [navigate]);

	useEffect(() => {
		if (user.userToken && !user.userPermission) {
			navigate('/board');
		}
	}, []);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			userEmail: '',
			userPassword: '',
		},
		validate: (values) => {
			const errors: { userEmail?: string; userPassword?: string } = {};

			if (!values.userEmail) {
				errors.userEmail = 'Required';
			}

			if (!values.userPassword) {
				errors.userPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			const account = {
				userEmail: values.userEmail,
				userPassword: values.userPassword,
			};
			API.narlabUserLogin(account)
				.then((res) => {
					if (setUser) {
						setUser(res);
					}
					handleOnClick();
				})
				.catch((err) => {
					// console.log(err);
					if (err.status === 401) {
						showNotification(
							'Errors',
							'This account has not been verified through email. Please proceed to your inbox for verification.',
							'danger'
						);
						dispatch({
							type: 'CONFIRM_SHOW',
							payload: {
								title: 'Would you like to resend the verification email ?',
								content: (
									<div>
										<p>This account has not yet been verified through email.</p>
										Would you like to resend the verification email?
									</div>
								),
								func: () => {
									API.sendConfirmUserEmail({ userId: err.error })
										.then(() => {
											showNotification(
												<span className="d-flex align-items-center">
													<Icon icon="Info" size="lg" className="me-1" />
													<span>Verification email sent successfully!</span>
												</span>,
												'',
												'success'
											);
										})
										.catch((err) => {
											showNotification('Errors', err.error, 'danger');
										});
								},
							},
						});
					} else {
						showNotification('Errors', err.error, 'danger');
						formik.setFieldError('loginPassword', err.error.message);
					}
				});
		},
	});

	const signupFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			userPassword: '',
			userName: '',
			userLevel: '',
			userEmail: '',
			userOrganization: '',
			confirmPassword: '',
		},
		validate: (values) => {
			const errors: {
				email?: string;
				confirmPassword?: string;
				userPassword?: string;
				userName?: string;
				userLevel?: string;
				userEmail?: string;
				userOrganization?: string;
			} = {};

			if (!values.userName) {
				errors.userName = 'Required';
			}

			if (!values.userLevel) {
				errors.userLevel = 'Required';
			}

			if (!values.userEmail) {
				errors.userEmail = 'Required';
			}

			if (!values.userOrganization) {
				errors.userOrganization = 'Required';
			}

			if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.userEmail)) {
				errors.userEmail = 'Email Errors';
			}

			if (!values.userPassword) {
				errors.userPassword = 'Required';
			}

			if (!values.confirmPassword) {
				errors.confirmPassword = 'Required';
			}

			if (values.confirmPassword !== values.userPassword) {
				errors.confirmPassword = 'Passwords did not match';
			}

			return errors;
		},
		validateOnChange: true,
		onSubmit: (values) => {
			const req = {
				userPassword: values.userPassword,
				userName: values.userName,
				userLevel: values.userLevel,
				userEmail: values.userEmail,
				userOrganization: values.userOrganization,
			};

			API.narlabUserCreate(req)
				.then((res) => {
					API.sendConfirmUserEmail({ userId: res })
						.then(() => {
							showNotification(
								<span className="d-flex align-items-center">
									<Icon icon="Info" size="lg" className="me-1" />
									<span>
										Please check your inbox for the verification email.
									</span>
								</span>,
								'',
								'success'
							);
							signupFormik.resetForm();
							setSignInPassword(false);
							setSingUpStatus(false);
						})
						.catch((err) => {
							showNotification('Errors', err.error, 'danger');
						});
				})
				.catch((err) => {
					showNotification('Errors', err.error, 'danger');
				});
		},
	});

	const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
		if (isNewUser) {
			return (
				<>
					<div className="text-center h1 fw-bold mt-5">
						{t('Sign Up Title')}
					</div>
					<div className="text-center h4 text-muted mb-5">
						{t('Sign Up Content')}
					</div>
				</>
			);
		}
		return (
			<>
				<div className="text-center h1 fw-bold mt-5">{t('Login Title')}</div>
				<div className="text-center h4 text-muted mb-5">
					{t('Login Content')}
				</div>
			</>
		);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? t('Sign Up') : t('Login')}
			className={classNames({
				'bg-light': !singUpStatus,
				'bg-dark': singUpStatus,
			})}
		>
			<Page className="p-0">
				<div className="row h-100 align-items-center justify-content-center">
					<div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
						<Card
							className={classNames({
								'shadow-3d-dark': !singUpStatus,
								'shadow-3d-light': singUpStatus,
							})}
							data-tour="login-page"
						>
							<CardBody>
								<div className="text-center my-5">
									<Icon icon="AccountCircle" size="4x" />
									<Link
										to="/"
										className={classNames(
											'text-decoration-none  fw-bold display-6',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											}
										)}
									>
										<br />
										{/* <Logo width={300} /> */}
										Fish-Eye Traffic Data Competition Website
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}
								>
									<div className="row row-cols-2 g-3 pb-3 px-3 mt-0">
										<div className="col">
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className="rounded-1 w-100"
												size="lg"
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(false);
												}}
											>
												{t('Login')}
											</Button>
										</div>
										<div className="col">
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className="rounded-1 w-100"
												size="lg"
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(true);
												}}
											>
												{t('Sign Up')}
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className="row g-4">
									{singUpStatus ? (
										<>
											<div className="col-6">
												<FormGroup id="userName" isFloating label="Name">
													<Input
														type="text"
														value={signupFormik.values.userName}
														onChange={signupFormik.handleChange}
														autoComplete="username"
														invalidFeedback={signupFormik.errors.userName}
														onBlur={signupFormik.handleBlur}
														isTouched={signupFormik.touched.userName}
														isValid={signupFormik.isValid}
													/>
												</FormGroup>
											</div>
											<div className="col-6">
												<FormGroup id="userLevel" isFloating label="Position">
													<Input
														type="text"
														value={signupFormik.values.userLevel}
														onChange={signupFormik.handleChange}
														autoComplete="userLevel"
														invalidFeedback={signupFormik.errors.userLevel}
														onBlur={signupFormik.handleBlur}
														isTouched={signupFormik.touched.userLevel}
														isValid={signupFormik.isValid}
													/>
												</FormGroup>
											</div>
											<div className="col-12">
												<FormGroup
													id="userOrganization"
													isFloating
													label="Company"
												>
													<Input
														value={signupFormik.values.userOrganization}
														onChange={signupFormik.handleChange}
														autoComplete="email"
														invalidFeedback={
															signupFormik.errors.userOrganization
														}
														onBlur={signupFormik.handleBlur}
														isTouched={signupFormik.touched.userOrganization}
														isValid={signupFormik.isValid}
													/>
												</FormGroup>
											</div>
											<div className="col-12">
												<FormGroup id="userEmail" isFloating label="Your email">
													<Input
														value={signupFormik.values.userEmail}
														onChange={signupFormik.handleChange}
														autoComplete="email"
														invalidFeedback={signupFormik.errors.userEmail}
														onBlur={signupFormik.handleBlur}
														isTouched={signupFormik.touched.userEmail}
														isValid={signupFormik.isValid}
													/>
												</FormGroup>
											</div>
											<div className="col-6">
												<FormGroup
													id="userPassword"
													isFloating
													label="Password"
												>
													<Input
														type="password"
														value={signupFormik.values.userPassword}
														onChange={signupFormik.handleChange}
														autoComplete="password"
														invalidFeedback={signupFormik.errors.userPassword}
														onBlur={signupFormik.handleBlur}
														isTouched={signupFormik.touched.userPassword}
														isValid={signupFormik.isValid}
													/>
												</FormGroup>
											</div>
											<div className="col-6">
												<FormGroup
													id="confirmPassword"
													isFloating
													label="Confirm"
												>
													<Input
														type="password"
														value={signupFormik.values.confirmPassword}
														onChange={signupFormik.handleChange}
														invalidFeedback={
															signupFormik.errors.confirmPassword
														}
														onBlur={signupFormik.handleBlur}
														isTouched={signupFormik.touched.confirmPassword}
														isValid={signupFormik.isValid}
													/>
												</FormGroup>
											</div>
											<div className="col-12">
												<Button
													color="info"
													className="w-100 py-3"
													onClick={signupFormik.handleSubmit}
												>
													{t('Sign Up')}
												</Button>
											</div>
										</>
									) : (
										<>
											<div className="col-12">
												<FormGroup
													id="userEmail"
													isFloating
													label={t('Your email')}
													// className={classNames({
													// 	'd-none': signInPassword,
													// })}
												>
													<Input
														autoComplete="userEmail"
														value={formik.values.userEmail}
														isTouched={formik.touched.userEmail}
														invalidFeedback={formik.errors.userEmail}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														// onFocus={() => {
														// 	formik.setErrors({});
														// }}
													/>
												</FormGroup>
												{/* {signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>Hi, {formik.values.loginUsername}.</div>
												)} */}
												<FormGroup
													className="mt-3"
													id="userPassword"
													isFloating
													label={t('password')}
													// className={classNames({
													// 	'd-none': !signInPassword,
													// })}
												>
													<Input
														type="password"
														autoComplete="current-password"
														value={formik.values.userPassword}
														isTouched={formik.touched.userPassword}
														invalidFeedback={formik.errors.userPassword}
														validFeedback="Looks good!"
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className="col-12">
												{/* {!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														{t('Continue')}
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														{t('Login')}
													</Button>
												)} */}
												<Button
													color="warning"
													type="submit"
													className="w-100 py-3"
													onClick={formik.handleSubmit}
												>
													{t('Login')}
												</Button>
												<div className="text-start m-2">
													<a
														href="/forget"
														className={classNames(' me-3', 'link-dark')}
													>
														Forgot password ?
													</a>
												</div>
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>OR</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													{t('Sign in with Apple')}
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													{t('Continue with Google')}
												</Button>
											</div>
										</>
									)} */}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
